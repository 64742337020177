.topbar {
  padding-top: 5px;
  padding-bottom: 5px;

  ul {
    display: flex;
    align-items: center;
    gap: 20px;

    li {

      p,
      a {
        color: #fff;
        margin-bottom: 0;
      }

      p {
        font-weight: 500;
      }
    }
  }
}
// .nav-link.activemegamenu .megamenheader{
//   opacity: 1;
//   visibility: visible;
// }

.megamenheader{
    background: #ddc99e;
    top:4pc;
    // border-bottom: 2px solid #fcaf17;
    border-top: 1px solid #e6e6e6;
    box-shadow: 0 4px 6px -1px rgba(0,0,0,.1), 0 2px 4px -1px rgba(0,0,0,.06);
    left: 0;
    padding: 20px 0;
    position: absolute;
    transition: all .3s ease-in;
    -webkit-transition: all .3s ease-in;
    -moz-transition: all .3s ease-in;
    -ms-transition: all .3s ease-in;
    -o-transition: all .3s ease-in;
    width: 100%;
    z-index: 9999;
    // cursor: initial;
    // opacity: 0;
    // visibility: hidden;
    ul.category-list {
      -webkit-column-gap: 2pc;
      column-gap: 2pc;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      height: inherit;
      justify-content: flex-start;
      row-gap: 1pc;
      li{
        width: 15vw;
      }
  }
}


// .visiblshow {
//   opacity: 1;
//   visibility: visible;
//   z-index: 99999999999;
// }
// .visiblhide {
//   opacity: 0;
//   visibility: hidden;
//   z-index: -20;
// }

.supplier-detail{
  margin: 0 4pc;
}
.middle-bar {
  &.navbar {
    form.search-bar {
      display: flex;

      .form-control {
        background: #eeeeee;
        border: 0;
        border-radius: 0;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 350px;

        input {
          border: 0;
          background: transparent;
          width: 100%;
        }
      }

      .icon {
        font-size: 22px;
      }

      button.btn {
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          font-size: 26px;
          line-height: 20px;
        }
      }

      ul.custom-search-list {
        width: 100%;

        label {
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 14px;
        }
      }
    }

    .navbar-nav {
      justify-content: center;
      gap: 20px;

      .nav-link {
        cursor: pointer;
        font-weight: 500;
        color: #000;
      }
    }

    .sign-in-btn {
      button {
        gap: 5px;
        border: 0;
        background-color: transparent;

        img {
          width: auto;
          height: 40px;
          object-fit: contain;
        }
      }

      .custom-search ul.custom-search-list {
        border-radius: 15px;
        width: max-content;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 1pc;
        padding: 1pc 0;

        li {
          border: 0;
          padding: 8px 30px;

          a {
            display: flex;
            align-items: center;
            gap: 10px;
            color: #000;
          }

          &.bottom-li {
            border-top: 1px solid lightgray !important;
            margin-top: 1pc;
            padding-top: 1pc !important;

            button.btn {
              font-weight: 500;
              padding: 8px 2pc;
            }

            p.text {
              font-size: 14px;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 3px;
              margin-top: 10px;
            }
          }

          .icon {
            font-size: 18px;
          }
        }
      }
    }

    .offcanvas-body {
      justify-content: space-around;
    }
  }
}

.bottom-bar {
  &.navbar {
    background-color: #ddc99b;

    .navbar-nav {
      justify-content: center;
      gap: 20px;

      .nav-link {
        cursor: pointer;
        font-weight: 500;
        color: #000;


        a {
          color: #000;
          position: relative;

          &:hover{
            color: #fff;
          }

          &::after{
            background: #000;
            bottom: -2px;
            content: "";
            height: 2px;
            left: 0;
            position: absolute;
            transition: all .5s ease;
            -moz-transition: all .5s ease;
            -webkit-transition: all .5s ease;
            width: 0;
          }
          &:hover::after{
            width: 100%;
          }


        }

        // &:hover {
        //   border-bottom: solid 1px black;

        // }


        &:hover .custom-dropdown {
          transition: all 0.5s;
          height: max-content;
          min-height: 25vh;
        }
      }
      .active{
        a{
          border-bottom: 2px solid #000;
          margin-top: 10px;

        }
      }
    }
  }
}

header {

  .topbar,
  .middle-bar,
  .bottom-bar {
    padding-left: 4pc;
    padding-right: 4pc;
  }
}

.custom-search {
  position: relative;

  .custom-search-list {
    // opacity: 0;
    // visibility: hidden;
    transition: all 0.5s ease-in-out;
    position: absolute;
    left: 0px;
    background: #fff;
    box-shadow: 0 0 20px #00000020;
    border-radius: 0 0 15px 15px;
    z-index: 99999 !important;
    width: 200px;

    // &.show {
    //   opacity: 1;
    //   z-index: 99999;
    //   visibility: visible;
    // }

    li {
      padding: 10px 12px;

      &:not(:last-child) {
        border-bottom: 1px solid lightgray;
      }
    }
  }
}

.custom-dropdown {
  position: absolute;
  left: 0;
  width: 100%;
  /* height: 50vh; */
  background: #ddc99b;
  overflow: hidden;
  transform: translateY(16px);
  height: 0;
  transition: all 0.5s;
  z-index: 999;

  &-inner {
    padding: 2pc 0;
    height: inherit;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  ul.category-list {
    height: inherit;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 2pc;
    row-gap: 1pc;
    justify-content: flex-start;

    li {
      width: 15vw !important;
    }

    .link {
      color: #000;
    }
  }
}

.main-banner {
  background-image: url("../images/top_banner.jpeg");
  background-size: cover;
  background-attachment: fixed;
  height: 70vh;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 0 2000px #00000099;

  .content {
    color: #fff;
    text-align: center;

    h1,
    h6 {
      color: #fff;
    }

    p.desp {
      margin: 2pc 0;
      font-weight: 600;
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      gap: 10px;

      li .icon {
        height: 70px;
        width: 70px;
        margin: 0 auto 1pc;
        border: 1px solid $yellow;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 37px;
        border-radius: 50%;
        padding: 10px;
      }
    }
  }
}

.page-banner {
  background-image: url("../images/top_banner.jpeg");
  background-size: cover;
  background-position: center;
  height: 40vh;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 0 2000px rgb(0 0 0 / 22%);
  margin: 0 4pc;
  border-radius: 10px;

  .content {
    color: #fff;
    text-align: center;

    h1,
    h6 {
      color: #fff;
    }

    p.desp {
      margin: 2pc 0 0;
      font-weight: 600;
    }
  }
}

.category {
  margin-right: 10px;

  .box {
    position: relative;
    margin: 0 0 30px 10px;
    // height: 400px;
    overflow: hidden;

    // &:hover img {
    //   transform: scale(1.3) rotate(5deg);
    //   transition: all 0.5s;
    // }

    img {
      height: 276px;
      width: 100%;
      // object-fit: cover;
      transition: all 0.5s;
      border-radius: 10px;
      box-shadow: rgb(0 0 0 / 38%) 0px 1px 4px;
    }

    .text {
      // position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      // background: #60320094;
      padding: 16px 5px;
      // text-align: center;
      backdrop-filter: blur(1px);

      h5 {
        margin: 0;
        color: #000;
        font-size: 20px;
        text-align: center;
      }
    }
  }
}

.product-box {
  border-radius: 12px;
  border: 1px solid lightgray;
  padding: 1pc;
  position: relative;
  background-color: #fff;

  img.img {
    width: 100%;
    height: 215px;
    object-fit: cover;
    border-radius: 12px;
  }

  button.call-btn {
    background: #39ab68;
    border: 9px solid #fff;
    color: #fff;
    border-radius: 50%;
    height: 70px;
    width: 70px;
    font-size: 30px;
    position: absolute;
    top: -25px;
    right: -20px;

    svg {
      transition: all 0.5s ease-in-out;
    }

    &:hover svg {
      transform: rotate(45deg) scale(0.9);
    }
  }

  .content {
    text-align: center;
    padding: 1pc 1pc 0;

    h6 {
      font-weight: 600;
      font-size: 14px;

      &.size {
        margin: 1pc 0;
      }

      &.prize {
        margin: 0;
        color: $brown;
      }
    }
  }
}

.flash-sale-box {
  background: #013c6f;
  color: #fff;
  font-family: auto;
  text-align: center;
  padding: 2pc;
  border-radius: 20px;
  height: 100%;

  h1,
  h4 {
    color: #fff;
    margin: 0;
  }

  .offer {
    margin-top: 2pc;

    h4 {
      font-size: 32px;
    }
  }

  .title {
    background-image: url("../images/decor/sale-bg.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      font-size: 60px;
    }
  }
}

.product-box-2 {
  border: 1px solid lightgray;
  padding: 1pc;
  border-radius: 15px;
  width: 90%;
  background: #fff;

  img.img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }

  h6.title {
    position: absolute;
    bottom: 0;
    left: 5%;
    background: #fff;
    padding: 19px;
    border-radius: 10px 10px 0 0;
    text-align: center;
    width: 90%;
    margin: 0;
    padding-bottom: 0;
    font-size: 15px;
  }

  ul.tags {
    position: absolute;
    top: 0;
    right: 0;

    li {
      background: #f74d57;
      margin: 6px 0;
      padding: 3px 10px;
      border-radius: 5px 0 0 5px;
      color: #fff;
      font-weight: 600;
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1pc;
    padding: 0 1pc;

    button.call-btn {
      background: #f74d57;
      color: #fff;
      border: 0;
      width: 60px;
      border-radius: 5px;
      font-size: 22px;
      padding: 5px 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h6 {
      font-size: 15px;

      .prize {
        font-weight: 600;
      }

      &.old .prize {
        text-decoration: line-through;
      }

      &.new {
        margin-bottom: 0;

        span.prize {
          color: #f74d57;
        }
      }
    }
  }
}

.expired-notice {
  text-align: center;
  padding: 2rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  margin: 0.5rem;

  &>span {
    font-size: 1rem;
    font-weight: bold;
    color: red;
  }

  &>p {
    font-size: 1.5rem;
  }
}

.show-counter {
  padding: 0.5rem;
  font-family: $poppin;

  .countdown-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.75rem;
    padding: 0.5rem;
    // border: 1px solid #ebebeb;
    border-radius: 0.25rem;
    text-decoration: none;
    color: #000;
  }

  .countdown {
    line-height: 1.25rem;
    padding: 0 0.75rem 0 0.75rem;
    align-items: center;
    display: flex;
    flex-direction: column;

    &+p {
      color: #fff;
    }

    &>p {
      margin: 0;
      border: 1px solid #ebebeb;
      padding: 10px;
      border-radius: 5px;
      color: #fff;
      min-width: 47px;
    }

    &>span {
      text-transform: uppercase;
      font-size: 10px;
      line-height: 1rem;
      color: #fff;
      margin-top: 10px;
      font-weight: 500;
    }
  }
}

.find-supplier {
  border: 1px solid lightgray;
  padding: 1pc;
  border-radius: 15px;
  background: #fff;
  text-align: center;

  img.img {
    border-radius: 10px;
    width: 100%;
    height: 150px;
    object-fit: cover;
  }

  h6 {
    font-size: 19px;
    margin: 1pc 0 0;
  }
}

.vender-box {
  text-align: center;

  .inner {
    border: 1px solid lightgray;
    padding: 1pc;
    border-radius: 15px;
    background: #fff;
  }

  img.img {
    border-radius: 10px;
    width: 100%;
    height: 150px;
    object-fit: cover;
  }

  h6 {
    padding: 0 1pc;
    margin: 1pc 0;
  }

  .btn {
    margin-top: -35px;
  }
}

.clients-box {
  text-align: center;

  img {
    height: 85px;
    width: auto;
    object-fit: contain;
  }
}

.more-box {
  position: relative;
  height: 100%;

  .icon {
    background: $yellow;
    color: #fff;
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 44px;
    border-radius: 50%;
    margin: 0 auto 0pc;
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
  }

  .content {
    text-align: center;
    box-shadow: 0 0 20px #00000020;
    padding: 5pc 2pc 2pc;
    border-radius: 12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    h4 {
      font-weight: 700;
    }
  }
}

.contact-us {
  background-image: url("../images/decor/bg-1.png");
  background-size: 100% 100%;

  .left {
    padding-top: 4pc;

    .heading {
      margin-bottom: 4pc;
    }

    .list {
      display: flex;
      align-items: flex-start;
      justify-content: space-evenly;
      gap: 10px;

      li {
        width: 33.33%;
        text-align: center;

        .icon {
          height: 70px;
          width: 70px;
          margin: 0 auto 1pc;
          border: 1px solid $yellow;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 37px;
          border-radius: 50%;
          background-color: $brown;
          padding: 10px;
          color: white;
        }

        h6 {
          font-weight: 600;
          margin-bottom: 0;
        }
      }
    }
  }

  .right {
    form.form {
      box-shadow: 0 0 20px #00000020;
      padding: 2pc;
      border-radius: 15px;
      background-color: #fff;
    }
  }
}

.news-letter {
  background-color: #fff7e9;
}

footer {
  background-color: #000;
  // position: relative;

  // &:before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   height: 100%;
  //   width: 100%;
  //   z-index: 0;
  //   opacity: 0.3;
  //   background-image: url("../images/favicon.png");
  //   background-repeat: no-repeat;
  //   background-position: right bottom;
  //   background-size: 17%;
  //   background-attachment: fixed;
  // }

  .copyright,
  .footer {
    position: relative;
    z-index: 6;
  }
}

.footer {
  padding: 4pc 0;
  color: #ffffff80;

  a {
    color: inherit;
  }

  .icon,
  a {
    &:hover {
      color: $brown;
    }
  }

  .title {
    color: $brown;
    margin-bottom: 1.5pc;
    font-weight: 700;
  }

  .social-links {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 1pc;

    li a {
      height: 40px;
      width: 40px;
      border: 1px solid #ffffff80;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;

      &:hover {
        background-color: $brown;
        color: $white;
        border-color: $brown;
      }
    }
  }

  .links {
    li {
      display: flex;
      align-items: flex-start;
      gap: 10px;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      .icon {
        color: $brown;
      }
    }
  }
}

.copyright {
  padding: 10px 0;
  border-top: 1px solid #ffffff20;
  color: #fff;

  a {
    color: inherit;
  }
}

.shop-page {
  .product-name {
    display: flex;
    align-items: center;
    gap: 2px;

    li {
      font-weight: 600;
      font-size: 17px;

      .icon {
        font-size: 25px;
      }
    }
  }
}

.shop-filter {
  border: 1px solid lightgray;
  padding: 1pc;

  .title {
    font-weight: 600;
    margin-bottom: 1pc;
  }

  .comm-list li {
    margin-bottom: 5px;
  }

  .box {
    padding: 1pc 0;

    &:not(:last-child) {
      border-bottom: 1px solid lightgray;
    }

    &:last-child {
      padding-bottom: 0;
    }

    label {
      display: flex;
      align-items: center;
      gap: 7px;
      font-weight: 500;
      color: #57577a;
      cursor: pointer;
    }
  }

  .inner-list {
    padding-left: 1pc;
  }

  .list {
    max-height: 250px;
    overflow: hidden;
    overflow-y: scroll;
    padding-right: 10px;

    &::-webkit-scrollbar {
      width: 5px;

      &-thumb {
        background: #bebcbc;
        border-radius: 10px;
      }

      &-track {
        background: #f1f1f1;
      }
    }
  }

  .price-range {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.category-page {
  img {
    width: 100%;
    height: 250px;
    border-radius: 10px;
    margin-bottom: 1pc;
  }

  .title {
    line-height: normal;
    font-weight: 600;
  }

  .sub-category li {
    font-weight: 500;
    color: #333232;
  }
}

.custom-modal {
  position: relative;

  .btn-close {
    position: absolute;
    top: 1pc;
    left: 1pc;
    opacity: 0.8;

    &.right {
      left: auto;
      right: 1pc;
    }
  }
}

.sign-in-modal {
  text-align: center;
  padding: 3pc;

  img.country-img {
    height: 30px;
    width: 30px;
    object-fit: contain;
  }

  .form-control {
    display: flex;
    align-items: center;

    .input {
      font-weight: 600;
      display: flex;
      align-items: center;
      color: #000;

      input {
        font-weight: inherit;
      }
    }
  }

  .custom-search {
    padding-right: 10px;

    p.pointer {
      display: flex;
      align-items: center;
    }

    &-list {
      border-radius: 0;

      li {
        padding: 4px 10px;

        img {
          height: 30px;
          width: 30px;
          object-fit: contain;
        }
      }
    }
  }

  .heading {
    margin: 1pc 0;
  }
}

.product-page {
  ul.mini-img {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1pc;
    justify-content: center;
    margin-top: 1pc;

    img {
      height: 100px;
      width: 100px;
      cursor: pointer;
      object-fit: cover;
      border: 1px solid lightgray;
      padding: 3px;
    }
  }

  .big-img {
    overflow: hidden;
    border-radius: 10px;

    &:hover img {
      transform: scale(1.5);
      transition: all 1s;
    }

    img {
      height: 400px;
      width: 100%;
      transition: all 1s;
      border-radius: inherit;
    }
  }

  .mid {
    ul.info {
      width: 80%;
      background: #cccccc24;

      li {
        padding: 7px 1pc;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 600;

        &:not(:last-child) {
          border-bottom: 1px solid #ccc;
        }
      }
    }

    .desp {
      margin: 1pc 0 10px;
      font-size: 15px;

      p {
        line-height: normal;
      }
    }

    .sizes ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      row-gap: 10px;
      column-gap: 10px;

      li {
        background: #cccccc4d;
        color: #000;
        font-size: 14px;
        font-weight: 500;
        padding: 10px 14px;
        cursor: pointer;
      }
    }

    .btns {
      margin: 1pc 0;
      display: flex;
      align-items: center;
      gap: 1pc;

      .btn-custom {
        --bs-btn-padding-x: 2pc;
        --bs-btn-padding-y: 13px;
      }
    }
  }

  .right {
    background-color: #fff7e9;
    padding: 1pc;

    p,
    .list-circle li {
      font-size: 13px;
      line-height: normal;
      font-weight: 500;
      margin-bottom: 0;
      color: #000;
    }

    .info {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 5px;
      margin-bottom: 10px;

      .img {
        height: 80px;
        width: 80px;
        object-fit: cover;
      }

      .supplier-rating {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }

    .btn {
      --bs-btn-padding-y: 8px;
    }
  }
}

.product-tabs {
  .tab-description {
    ul.info {
      width: 100%;
      background: #cccccc24;

      li {
        padding: 7px 1pc;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 600;

        &:not(:last-child) {
          border-bottom: 1px solid #ccc;
        }
      }
    }
  }

  ul.tabs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 2px solid #ccc;
    gap: 2pc;

    li {
      padding-bottom: 1pc;
      color: #000;
      font-weight: 600;
      font-size: 17px;
      cursor: pointer;

      &.active {
        color: $brown;
        border-bottom: 2px solid;
        margin-bottom: -2px;
      }
    }
  }

  .tab-inner {
    padding-top: 2pc;

    .desp {
      p {
        margin-bottom: 0;
      }
    }
  }
}

.swiper-button {

  &-prev,
  &-next {
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 20px #00000020;

    &:after {
      color: $yellow;
      font-size: 25px;
      font-weight: bold;
    }
  }
}

.product-review {
  box-shadow: 0 0 20px #00000020;
  border-radius: 10px;
  padding: 2pc;
  border: 1px solid transparent;
  background-color: #fff;
  transition: all 0.5s;

  &:hover {
    border-color: $brown;
    transform: scale(0.98);
    box-shadow: none;
    transition: all 0.5s;
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .name {
      display: flex;
      align-items: center;
      gap: 16px;

      img {
        height: 90px;
        width: 90px;
        border-radius: 50%;
        object-fit: cover;
      }

      p {
        font-weight: 600;
      }

      h6 {
        margin: 0;
        font-weight: 600;
        font-size: 19px;
      }
    }
  }

  .desp p {
    line-height: normal;
    font-size: 15px;
    margin-top: 10px;
  }
}

.supplier-detail {
  .box {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;

    li.left {
      img {
        height: 150px;
        width: 150px;
        border-radius: 10px;
        object-fit: cover;
      }

      ul.inner {
        display: flex;
        align-items: center;
        gap: 1pc;

        ul li {
          display: flex;
          // align-items: center;
          gap: 10px;
          color: #000;
          font-weight: 500;
          margin-bottom: 5px;
        }
      }

      ul.innner1 {
        .icon-brown-list {
          font-size: 32px;
          font-weight: bold;
          margin-bottom: 30px;

        
        }
        span{
          font-size: 32px;
        }
      }
    }

    li.right ul {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 10px;

      a {
        width: 180px;
        justify-content: center;
      }
    }
  }
}

.supplier-product-box {
  img.img {
    height: 285px;
  }
}

.supplier-products {
  .box {
    display: flex;
    gap: 1pc;
    align-items: center;
    border-radius: 12px;
    box-shadow: 0 0 20px #00000020;
    background: #fff;

    .image {
      width: 45%;
      position: relative;

      img {
        width: 100%;
        height: 225px;
        object-fit: cover;
        border-radius: 12px 0 0 12px;
      }

      .btn {
        position: absolute;
        bottom: 0;
        right: 0;
        border-radius: 0;
        padding: 11px 20px;
      }
    }

    .content {
      padding: 1pc;

      p {
        margin: 0;
        font-size: 15px;
        line-height: normal;
        font-weight: 500;
      }

      h5 {
        font-weight: 600;
      }
    }
  }
}

.product-video {
  .image {
    height: 300px;

    iframe {
      object-fit: cover;
      border-radius: 15px;
    }
  }

  .content {
    margin-top: 10px;

    h5 {
      font-weight: 600;
      margin-bottom: 0;
    }

    p {
      font-size: 14px;
      line-height: normal;
    }
  }
}

.supplier-profile {
  .box {
    display: flex;
    gap: 1pc;
    background: #fff;
    padding: 2pc 1pc;
    border-radius: 10px;
    box-shadow: 0 0 20px #00000020;
    border: 1px solid transparent;
    transition: all 0.5s;

    .icon {
      font-size: 30px;
    }

    .content {
      h5 {
        font-weight: 600;
      }

      p {
        font-size: 13px;
        font-weight: 500;
      }
    }

    &:hover {
      transform: scale(0.99);
      border-color: $brown;
      transition: all 0.5s;
    }
  }
}


.supplier-tabs {
  li {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}











/////////////////////////////////////////subscription section
.subscription-container {
  background-color: "#FAFBFD";


  .subsctiption-heading {
    font-size: 40px;
    margin: 50px 0px 18px 0px;
    color: black;
    font-weight: 600;
  }

  .subscription-description {
    font-size: 18px;
    width: 50%;
    margin: 0px 0px 30px 0px;
    color: rgba(0, 0, 0, 0.575);
    font-weight: 400;
  }


  .subscription-card {
    width: 30%;
    margin: 20px 1.5%;
    min-height: 500px;
    background-color: white;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 23px -7px rgba(54, 53, 54, 0.253);
    -moz-box-shadow: 0px 0px 23px -7px rgba(54, 53, 54, 0.253);
    box-shadow: 0px 0px 23px -7px rgba(54, 53, 54, 0.253);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    position: relative;

    .subscription-card-heading {
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 30px;
    }

    .subscription-card-price {
      font-size: 21px;
      font-weight: 700;
      color: #DDC99B;
    }

    .subscription-card-days {
      font-size: 20px;
      font-weight: 500;
      color: #DDC99B;
    }

    .subscription-card-description {
      margin-top: 20px;
    }

    .subscription-card-message-list {
      width: 90%;
      margin-top: 30px;
    }

    .subscription-card-message-list li {
      list-style: circle;
      width: 100%;
    }

    .subsctiption-card-button {
      position: absolute;
      bottom: 30px;
    }

  }
}







/////////////////////////////////////////profile screen section
.profile-section-container {
  min-height: 150px;
  // width: 47%;
  // margin: 10px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.123);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.123);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.123);
  padding: 30px !important;
  border-radius: 10px !important;
  margin-bottom: 50px;


  .porfilebox {
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h4 {
      font-size: 25px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.5);
    }

    h5 {
      font-size: 20px;
      color: black;
      font-weight: 300;
    }
  }



}

.profile-section-Heading {
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

.profile-section-Sub-Heading {
  font-size: 18px;
  font-weight: 600;
}

.border-right {
  border-right: solid 1px rgba(128, 128, 128, 0.237) !important;
  height: inherit;
  width: 1px;
}

.theme-outline-button {
  border: solid 1px #603200;
  color: #603200;
  width: fit-content;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 5px 20px;
  background-color: white;
  border-radius: 6px;
}

.counter_absol {
  position: absolute;
  top: 60px;
  right: 15px;
}

.flash-sale {

  .show-counter {
    position: absolute;
    z-index: 99;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .overlyasper {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.623)
  }

  .countdown {
    padding: 0px;
  }

  .countdown>p {
    margin: 0;
    padding: 0px;
    // min-width: unset;
    text-align: center;
    border: 1px solid transparent;
    border-radius: 5px;
    color: #fff;
  }
}

.blogImage {
  height: 350px;
  width: 100%;
  object-fit: fill;
}

.main-blog {
  background-color: #f9f9f9;

  .al_center {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .blog_bx_left {
      width: 48%;
      height: 380px;
      box-shadow: 0 1px 6px -1px rgb(0 0 0 / 11%);
      border-radius: 8px;

      .blog__img {
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
        display: flex;
        margin: auto;
        border-radius: 12px;
      }
    }

    .blog_bx_right {
      width: 48%;

      .blog-headingtext {
        font-size: 45px;
        color: #000;
        font-weight: 500;
      }

      p {
        font-size: 19px;
        line-height: 1.5;
        margin-bottom: 15px;
      }

      .writer_name,
      .content_date {
        color: #919191;
        font-size: 15px;
      }
    }
  }

  .container-fluid {
    padding: 0px 9pc;
  }
}

.blog_listing {
  margin-bottom: 50px;

  .blog_listing_img {
    overflow: hidden;
    border-radius: 10px;

    img {
      width: 100%;
      height: 190px;
      border-radius: 10px;
      // object-fit: cover;
      -webkit-transition-delay: 0.1s;
      transition-delay: 0.1s;
      -webkit-transition-timing-function: ease-in-out;
      transition-timing-function: ease-in-out;
      -webkit-transition-duration: 0.7s;
      transition-duration: 0.7s;
      -webkit-transition-property: all;
      transition-property: all;
    }

    &:hover img {
      -webkit-filter: brightness(0.6);
      filter: brightness(0.6);
      -webkit-transition: 1s all;
      transition: 1s all;
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
  }

  .list_content_blog {
    h6 {
      margin: 20px 0;
      color: #fcaf17;
    }

    h4 {
      font-size: 22px;
      line-height: 30px;
      color: #4a4a4a;
      font-weight: 600;
    }

    p {
      margin: 12px 0;
    }

    .btn.blog_readmore {
      color: #000;
      font-weight: 600;
      padding-left: 0;
      font-size: 16px;

      &:hover {
        color: #fcaf17 !important;

        svg {
          font-size: 22px;
          transition: all 0.2s;
        }
      }

      svg {
        transition: all 0.2s;
      }
    }
  }
}

.blog-box {
  .btn {
    letter-spacing: 1px;
  }

  h4 {
    margin: 14px 0 5px;
    font-size: 25px;
    font-weight: 700;
    line-height: 1.5;

    a {
      color: #000000;
    }
  }

  .image {
    height: 275px;

    img {
      border-radius: 15px;
    }
  }

  ul.tags {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 10px;

    li {
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: 500;
      color: #4d4d4d;
    }
  }
}

.active-tab {
  background-color: #DDC99B;
  color: white;
  text-align: center;
  padding: 20px 0px 10px 0px;
  cursor: pointer;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;

}

.in-active {
  background-color: white;
  color: black;
  text-align: center;
  padding: 20px 0px 10px 0px;
  cursor: pointer;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid #ccc; 
}

.dataContainer h4 {
  margin-top: 25px;
}

.dataContainer ul li {
  list-style: circle;
  margin-bottom: 15px;
}

.sliderimg {
  height: 50vh;
  width: 100%;
}
.team_img img{
  height: 350px;
  width: 100%;
  object-fit: cover;
  margin-bottom: 25px ;
  border-radius: 5px;
}
.centercentr {
  text-align: center;
}

.middlebar_new .custom-search {
  position: relative;
}

.middlebar_new form.search-bar .form-control {
  background: #eeeeee;
  border: 0;
  border-radius: 0;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 350px;
}

.middlebar_new form.search-bar {
  display: flex;
}

.middlebar_new form.search-bar .pointer {
  display: flex;
  gap: 10px;
}


.middlebar_new form.search-bar .form-control input {
  border: 0;
  background: transparent;
  width: 100%;
}

.middlebar_new .middle-bar .navbar-nav {
  justify-content: center;
  gap: 20px;
}

.topheader_scroll{
  overflow-x: scroll;
}

.topheader_scroll::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}


.topheader_scroll::-webkit-scrollbar-track {
  background: #ddc99e;
}


// .topheader_scroll::-webkit-scrollbar-thumb {
//   background: #888;
// }

/* Handle on hover */
.topheader_scroll::-webkit-scrollbar-thumb:hover {
  background: #bea777;
  border-radius: 10px;
}
  

.webkitnone.navbar-nav{
  white-space: nowrap;
  text-overflow: ellipsis;
}
.middlebar_new .nav .nav-link {
  cursor: pointer;
  font-weight: 500;
  color: #000;
  position: relative;
}


.middlebar_new .nav .nav-link span{
  position: absolute;
  top: 3px;
  width: 16px;
  height: 16px;
  background-color: #b08229;
  color: #fff;
  left: 25px;
  border-radius: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
}
.middlebar_new .sign-in-btn button {
  gap: 5px;
  border: 0;
  background-color: transparent;
}

.middlebar_new .sign-in-btn button img {
  width: auto;
  height: 40px;
  object-fit: contain;
}

.middlebar_new {
  padding: 20px 4pc;

}

.middlebar_new .sign-in-btn .custom-search ul.custom-search-list {
  border-radius: 15px;
  width: max-content;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 1pc;
  padding: 1pc 0;
  width: 230px;
}

.custom-search .custom-search-list li a {
  display: flex;
  gap: 10px;
}

.searchBox.listsearch a p {
  padding-bottom: 5px;
  border-bottom: 1px solid #e1e1e157;
}

.supplierlist li {
  list-style: none;
}



.btn-outline-custom {
  color: $yellow;
  border-color: $yellow ;
}

.btn-outline-custom:hover {
  color: white;
  background-color: $yellow ;
}
.address{
  font-size: 13px;
  line-height: 21px;
}
.thankucard{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 6pc 0;
  text-align: center;
  margin: 5pc 0;
  border-radius: 5px;
  // height: calc(100vh - 681px);
}

// input rages css

.resendtp{
  color: #000;
  font-weight: 600;
  margin-top: 10px;
  cursor: pointer;
  text-decoration: underline;
}