html {
  scroll-behavior: smooth;
}

body {
  font-family: $poppin;
  color: #797979;
  line-height: 1.7em;
  font-weight: 400;
  background: $white;
  overflow-x: hidden;
}


.mb-80 {
  margin-bottom: 80px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.ptb-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.pt-80 {
  padding-top: 80px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.px-4pc {
  padding-left: 4pc;
  padding-right: 4pc;
}

.btn {
  &:focus {
    box-shadow: none;
  }

  &-custom {
    font-weight: 500;
    --bs-btn-padding-x: 1pc;
    // letter-spacing: 1px;
  }

  &-hover {
    position: relative;
    overflow: hidden;

    &:before {
      position: absolute;
      background: #ffffff80;
      height: 100%;
      width: 100%;
      top: 0;
      left: 100%;
      content: "";
      border-radius: 3px;
      transition: all 0.5s;
    }

    &:hover:before {
      transition: all 0.5s;
      left: 0;
      transform: translateX(-100%);
    }
  }

  &-brown {
    background-color: $brown;
    color: $white;
    transition: all 0.5s;

    &:hover {
      background-color: $black !important;
      color: $white !important;
      transition: all 0.5s;
    }
  }

  &-black {
    background-color: #363636;
    color: $white;

    &:hover {
      background-color: #363636 !important;
      color: $white !important;
    }
  }

  &-yellow {
    background-color: $yellow;
    color: $white;
    border-width: 2px;
    border-radius: 5px;

    &:hover {
      background-color: #fff !important;
      color: $yellow !important;
      border-color: $yellow;
    }
  }

  &-link-yellow {
    color: $yellow;
    border-bottom: $yellow solid 1px;
    // color: $white;
    padding: 0px;
    margin: 0px;
    width: max-content;
    border-radius: 0px !important;

    &:hover {
      // background-color: #fff !important;
      color: $yellow !important;
      border-bottom-color: $yellow;
    }
  }

  &.with-icon {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.poppin {
  font-family: $poppin !important;
}

.brown {
  color: $brown !important;
}

.brown-bg {
  background-color: $brown !important;
}

.yellow {
  color: $yellow !important;
}

.yellow-bg {
  background-color: $yellow !important;
}

.gray-bg {
  background-color: #f3f4f8 !important;
}

.fs-15 {
  font-size: 15px;
}

.list-circle {
  li {
    list-style-type: circle;
    list-style-position: inside;
  }
}

.flex-1 {
  flex: 1;
}

.heading {
  // line-height: 50px;
  font-weight: 700;
  letter-spacing: 2px !important;
 
}

.title-section {
  &.with-btn {
    text-align: center;
    position: relative;

    .heading {
      margin-bottom: 0;
    }

    .btn {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);

      &.btn-custom {
        --bs-btn-padding-x: 1.5pc;
        --bs-btn-padding-y: 7px;
      }
    }
  }
}

p {
  line-height: 1.8em;

  &:last-child {
    margin-bottom: 0;
  }
}

.img-cover {
  object-fit: cover;
}

.img-contain {
  object-fit: contain;
}

.img-position-top {
  object-position: top;
}

.img-fill {
  object-fit: fill;
}

a {
  text-decoration: none;
  color: #000;
  transition: all 0.5s;

  &:hover {
    transition: all 0.5s;
    color: $yellow;
  }
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $black;
}

.btn-sm {
  font-size: 0.875rem;
  // padding: 7px 22px;
  border-radius: 0.2rem;
}

.main-logo {
  height: 61px;
  width: auto;
  object-fit: contain;
  transition: all 0.5s;
}

.translateX {
  transform: translateX(0) !important;
}

.pointer {
  cursor: pointer;
}

.overlay {
  position: relative;

  &:before {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    content: "";
    z-index: 66;
    pointer-events: none;
  }
}

#scrollUp {
  position: fixed;
  right: 1pc;
  bottom: 1pc;
  opacity: 0;

  .scroll-inner {
    height: 45px;
    width: 45px;
    background: #f16331;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #fff;
    font-size: 22px;
    cursor: pointer;
  }
}

#call-us {
  position: fixed;
  right: 2pc;
  bottom: 1pc;
  filter: drop-shadow(0px 0px 6px gray);
  z-index: 9999;
  opacity: 0;

  &:before {
    content: "";
    position: absolute;
    border: 2px solid #fff;
    bottom: 0;
    right: 0;
    transform: translate(-2px, -2px);
    height: 15px;
    width: 15px;
    background: #128c7e;
    z-index: 0;
  }

  .call-us-inner {
    height: 55px;
    width: 55px;
    background: #128c7e;
    border-radius: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #fff;
    font-size: 30px;
    transform: scaleX(-1);

    i {
      animation: phone 1.5s linear 0s infinite alternate;
    }
  }
}

// #preloader {
//   position: fixed;
//   height: 100vh;
//   width: 100vw;
//   z-index: 99;
//   top: 0;
//   left: 0;
//   background: #f8f9fa;
//   .preloader-inner {
//     background-image: url("../images/loader.gif");
//     background-size: 16pc;
//     background-repeat: no-repeat;
//     background-position: center;
//     height: 100%;
//     width: 100%;
//   }
// }
.line {
  width: 70px;
  background: #fff;
  height: 1px;
}

.line-height-normal {
  line-height: normal;
}

input:focus-visible {
  outline: 0;
}

input[type="checkbox"] {
  height: 17px;
  width: 17px;
  border: 2px solid #d0d0d0;
  border-radius: 0 !important;
  position: relative;
  background-color: #fff;
  cursor: pointer;
  appearance: none;

  &.form-check-input {
    margin: 0;

    &:focus {
      border-color: #00000040;
      box-shadow: none;
    }

    &:checked {
      background-color: $brown;
      border-color: $brown;
    }
  }
}

input[type="radio"] {
  cursor: pointer;

  &:checked {
    background-color: $brown;
    border-color: $brown;
  }

  &:focus {
    border-color: $brown;
    outline: 0;
    box-shadow: 0 0 0 0.25rem #60320040;
  }
}

input[type="range"] {
  &::-webkit-slider-thumb {
    background: $brown;

    &:focus {
      background: $yellow !important;
    }
  }
}

.form {
  [class*="col-"] {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .form-control {
    background: #f5f5f5;
    border: 0;
    padding: 12px 1pc;
    font-weight: 500;
    color: $brown;

    &:focus {
      box-shadow: none;
      border-color: $brown;
      transition: all 0.5s;

      &::placeholder {
        opacity: 0;
        transition: all 0.5s;
      }
    }

    &::placeholder {
      opacity: 1;
      transition: all 0.5s;
      text-transform: capitalize;
    }
  }

  label {
    margin: 0;
    color: #000000;
    line-height: 20px;
    width: 100%;
    font-size: 15px;
    font-weight: 600;
  }
}

.address-map {
  margin: 0 10px;
  padding: 8px 60px 8px 8px;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  border-radius: 2px;
  background-color: white;
  position: absolute;
  top: -5px;
  left: -5px;
  text-align: left;
  z-index: 1;

  p,
  a {
    font-size: 15px;
  }
}


.check {
  appearance: auto !important;
}