body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.searchBox {
  background-color: white;
  height: 200px;
  position: absolute;
  top: 45px;
  z-index: 10000 !important;
  left: 4%;
  /* height: ; */
  overflow-y: scroll;
  padding: 10px;
  border-radius: 10px;
  width: 90%;
  border: 0.2px solid #cfcfcf;
}

.searchBox .searchBoxCloseIcon {
  position: absolute;
  top: 5px;
  right: 5px;
  font-weight: 700;
  color: black;
  font-size: 13px;
  cursor: pointer;
}

.navbar-nav .nav-link {
  padding: 15px !important;
}

.apply_buttn {
  width: 100%;
  background-color: #bf9f65;
  border: 1px solid #bf9f65;
  color: #fff;
  border-radius: 0.375rem;
  padding: 10px;
  font-size: 17px !important;
  text-transform: uppercase;
  margin-top: 20px;
}

.apply_buttn:hover {
  background-color: transparent;
  color: #bf9f65;
  transition: all 0.3s;
}

.clear_filter {
  padding: 0px;
  border: none;
  font-size: 14px;
  color: #bf9f65;
  background-color: transparent;
}

.clear_filter:hover {
  font-weight: 600;
  transition: 0.4s;
}

.shop-filter .title {
  margin-bottom: 0pc !important;
}

.dealerapply {
  background: #f6f4f5;
  font-size: 1rem;
  color: #000;
  border-radius: 30px;
  border: none;
}

.css-13cymwt-control, .css-nalcay-control {
  background: transparent !important;
  border: none !important;
}

.css-13cymwt-control :hover {
  background: transparent !important;
  border: none !important;
}

.css-13cymwt-control::placeholder {
  color: transparent;
}

.abc {
  background: transparent !important;
}

.searchbar2::placeholder {
  font-size: 12px !important;
}

@media (max-width: 600px) {
  .searchbar2::placeholder {
    font-size: 10px !important;
  }
}

.cvdizt {
  min-height: 0px !important;
  padding: 0px !important;
  padding-top: 10px !important;
  background: transparent !important;
}
.cgTKyH {
  background: transparent !important;
}

.notification-badge {
  position: absolute;
  top: -5px; /* Adjust as needed */
  right: -1px; /* Adjust as needed */
  background-color: #bf9f65;
  color: white;
  padding: 2px 6px;
  border-radius: 50%;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  border: 1px solid #fff;
  display: inline-block;
}

.css-b62m3t-container {
  height: auto;
}
.mobile-header {
  display: none;
}

@media (max-width: 600px) {
  .mobile-header {
    display: block !important;
  }
}
