.info li {
   background: transparent !important;

}
.userprofile{
   background-image: url('../image/home//Group\ 1000004150.png');
   background-position: center;
   background-size: cover;
   height: 600px;
}
@media only screen and (max-width: 991px) {
   .userprofile{
      background-image: url('../image/home//Group\ 1000004150.png');
      background-position: center;
      background-size: cover;
      height: 200px;
   }
}

.userprofileoptions {
   background: #EAE2D0;
   box-shadow: 8px 4px 20px 0px #00000040;
   border-radius: 40px;
}

.userprofileoptions .boxes {
   border-bottom: 1px solid #B8997F;




}


.userprofileoptionsicon {
   background: #bf9f65;
   color: white;
   border-radius: 50%;
   font-size: 24px;
   padding: 7px;
}
.userprofileoptionstext{
   color: #bf9f65;
}


.icon-circle {
   display: inline-flex;
   align-items: center;
   justify-content: center;
   width: 35px;  /* Adjust size as needed */
   height: 30px; /* Adjust size as needed */
   border-radius: 50%;
   background-color: #30a74d; /* Background color for the circle */
   margin-left: 20px; /* Space between text and icon */
   
 }
 .phn{
   background: #39ab68;
  cursor: pointer;
  border: 4px solid white;
  position: relative;
  top: -20px;
  color: white;
  font-size: 50px;
  margin-bottom: -35px;
 }
 