.main-blog .overlayflowscroll {
  background-color: #8b8b7f;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  /* width: max-content; */
}

.main-blog .overlayflowscroll .tab {
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  border-radius: 50px;
  padding: 2.5vh 3vw;
  width: max-content;
}

.main-blog .overlayflowscroll .tab.active-tab {
  /* background-color: #8b8b7f; */
  background-color: #bf9f65;
  border:2px solid #fff;
}

.main-blog .overlayflowscroll .tab.in-active {
  background-color: transparent;
  border: none;
}

.main-blog .overlayflowscroll .tab .blog2_heading {
  color: white;
  font-size: 1.6rem;
  font-weight: 600;
  text-transform: uppercase;
}

/* @media (max-width: 768px) {
  .main-blog .overlayflowscroll .tab span {
    font-size: 1.2rem;
  }
} */

.main-blog .news-1 .blog_listing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 8px 8px 14px 0px #00000040;
  border-radius: 30px;
  padding: 15px 8px 12px 8px;
  width: 300px;
  height: 360px;
}

.main-blog .news-1 .blog_listing .blog_listing_img {
  width: 100%;
  height: 47%;
  border-radius: 15px;
}

.main-blog .news-1 .blog_listing .blog_listing_img img {
  max-width: 100%;
  max-height: 100%;
}

.main-blog .news-1 .blog_listing .list_content_blog {
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
}

.main-blog .news-1 .blog_listing .list_content_blog h6 {
  color: #bf9f65;
  font-weight: 600;
  line-height: 1.5;
  margin: 10px 0 0 0;
}

.main-blog .news-1 .blog_listing .list_content_blog div {
  color: black;
  font-weight: 500;
  font-size: 13.5px;
  margin: 5px 0 auto 0;
}

.main-blog .news-1 .blog_listing .list_content_blog .blog_readmore {
  background-color: #bf9f65;
  /* box-shadow: 8px 8px 14px 0px #00000040; */
  color: white;
  font-weight: bold;
  border: #bf9f65 solid 2px;
  border-radius: 50px;
  padding: 8px 10px;
  margin: 0 0 8px 0;
}

.main-blog .news-1 .blog_listing .list_content_blog .blog_readmore:hover {
  color: #bf9f65 !important;
  border: #bf9f65 solid 2px;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: transparent !important;
}

.main-blog .video-2 .blog_listing {
  box-shadow: 8px 8px 14px 0px #00000040;
  border-radius: 30px;
  width: 300px;
  /*height: 350px;*/
  overflow: hidden;
}

.blog-detail .blog-box .header-blog-news {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5vmin;
}

.blog-detail .blog-box .header-blog-news div {
  background: linear-gradient(
    90deg,
    #bf9f65 0%,
    rgba(103, 67, 32, 0.580081) 66.96%,
    rgba(96, 50, 0, 0) 100%
  );
  color: white;
  border-radius: 60px 0 0 60px;
  padding: 3vmin 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  font-weight: 500;
}

@media (max-width: 768px) {
  .main-blog .overlayflowscroll .tab .blog2_heading {
  
    font-size: 1.2rem;
  }

  .main-blog .video-2 .blog_listing {
    height: auto;
    background-color:#fff;
    box-shadow: 8px 8px 14px 0px #fff;
  }
  .blogImage {
    height: auto;
  }
}
