.topup-subscription-container {
  /* background-image: url(../image/home/images/backimg.png); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 1px;
  background-color: #fff;
}

.topup-subscription-container .subscription-card {
  background-color: #ffffff;
  box-shadow: 12px 14px 35px 0px #00000040;
  border-radius: 20px;
  margin-bottom: 50px !important;
}

.topup-subscription-container .subscription-card .subscription-card-heading {
  background: linear-gradient(
    90deg,
    #bf9f65 0%,
    rgba(103, 67, 32, 0.580081) 66.96%,
    rgba(96, 50, 0, 0) 100%
  );
  color: white;
  /* font-size: 1.2rem; */
  text-align: center;
  border-radius: 50px 0 0 50px;
  padding: 5px 40px 5px 14px;
  margin-left: 20px;
}
.clr{
  color: #bf9f65;
  font-size: 15px;
  font-weight: 100px;

}
.topup-subscription-container .subscription-card .subscription-card-price {
  color: #bf9f65;
  /* font-weight: 800; */
  font-size: 3.5rem;
  line-height: 1;
}

.topup-subscription-container .subscription-card .subscription-card-days {
  color: black;
}

.topup-subscription-container .subscription-card .subsctiption-card-button {
  background-color: #bf9f65 !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  /* border: none; */
  border-radius: 50px;
  color: white;
  font-weight: 600;
  font-size: 1.2rem;
  padding: 2vh 3.5vw;
  margin-bottom: -50px;
}
