:root {
  ---lightwhite: #f9f8f7;
  --secondary: #bf9f65;
  --darkbrown: #bf9f65;
}
.section1 {
  justify-content: center;
  align-items: center;
}
.msg1 {
  height: 50px;
}

.categoriesback {
  /* background-image: url('../image/home/footer.png'); */
  background-color: #fff;
}

.img1 {
  height: 16rem;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.categoriescircle {
  background-color: #bf9f65;
}

.ctimg {
  height: 7rem;
  width: 8rem;
  border-radius: 11%;
  /* box-shadow: 8px 4px 17px 0px rgba(0, 0, 0, 0.25); */
}

.addoppoutnitybtn {
  background: #bf9f65;
  border: none;
  border-radius: 8px;
  padding: 1.3rem 2rem;
  font-size: 2rem;
  color: #fff;
  position: absolute;
  bottom: 20%;
  left: 10%;
}

.addoppoutnitybtn button {
  color: #fff;
  border: none;
  background: none;
}
@media only screen and (max-width: 600px) {
  .ratingcount {
    /* font-size: 14px; */
    /* new */
    font-size: 10px;
    padding: 3px;
    padding-left: 10px;
  }
}

@media (min-width: 1025px) {
  .ratingcount {
    /* font-size: 16px; */
    /* new */
    font-size: 15px;
    padding-left: 20px;
  }
}

.productyoumaylike {
  height: 500px;
}

@media (max-width: 1400px) {
  .ctimg {
    height: 100px !important;
    width: 100px !important;
  }
}
@media (min-width: 1201px) and (max-width: 1399px) {
  .newprdcrd {
    width: 29rem;
  }
}

.swiper-button-prev,
.swiper-button-next {
  right: -20px !important;
}

.new-section {
  background-color: var(---lightwhite);
}
/* ***********product you may like*************** */

/* .prdcrd {
  background: rgba(245, 241, 232, 1);
  border-radius: 30px;
  box-shadow: 8px 4px 17px 0px rgba(0, 0, 0, 0.25);

}

.prdcrd .prdbtn {
  background: rgba(96, 50, 0, 1);
  width: auto;
  border-radius: 30px;
  border: none;
  position: relative;
  top: 20px;
  box-shadow: 8px 4px 17px 0px rgba(0, 0, 0, 0.25);

}

.prdcrd .phn {

  background: rgba(96, 50, 0, 0.68);
  cursor: pointer;
  border: 5px solid white;
  position: relative;
  top: -35px;
  color: white;
  font-size: 60px;
  margin-bottom: -35px;
}

.vvall {
  background: rgba(96, 50, 0, 1);

} */

.product-container-section .box_Product1 {
  background-color: #f5f1e8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  box-shadow: 8px 4px 17px 0px #00000040;
  width: 38vmin;
  text-align: center;
  margin-bottom: 3vmin;
}

.product-container-section .box_Product1 img {
  border-radius: 8px;
  width: 100%;
  height: 10rem;
}

.topproduct-card {
  height: 340px !important;
}

.topproduct-card .image-container {
  padding: 12px !important;
  height: 55% !important;
}

.topproduct-card .product-details .card-body-city {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.topproduct-card .product-details .top-productbtn {
  border-radius: 10px;
  padding: 10px 12px;
}
.topproduct-card .product-details {
  padding-top: 0;
  align-items: flex-start !important;
}

.top-productbtn {
  background: #bf9f65;
  border: none;
  border-radius: 8px;
  padding: 10px 12px;
  font-size: 1rem;
  color: #fff;
  position: absolute;
  margin-top: 10px;
  bottom: 16px;
  /* width: 50%;
  left: 24%; */
}

.viewlbtn a {
  background: #bf9f65;
  border: none;
  border-radius: 8px;
  padding: 10px 12px;
  font-size: 1rem;
  color: #fff;
}
.viewlbtn button {
  background: #bf9f65;
  border: none;
  border-radius: 8px;
  padding: 10px 12px;
  font-size: 1rem;
  color: #fff;
}
.addviewtbn {
  float: right;
  margin-right: 60px;
}
.flashsale-title {
  padding-left: 16rem;
}
.product-container-section .box_Product1 .icn_Product {
  background-color: #39ab68;
  border: solid white 4px;
  border-radius: 100%;
  color: white;
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2vmin;
  margin-top: -4vmin;
}

.product-container-section .box_Product1 .product_icn_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #3d3b3b;
}

.product-container-section .box_Product1 .green-1 {
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 0;
}

.product-container-section .box_Product1 .chennai {
  font-weight: 600;
  font-size: 0.8rem;
  color: #bf9f65;
}
.sub-container1 .chennai {
  font-weight: 600;
  font-size: 1.1rem;
  color: #bf9f65;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  text-transform: capitalize;
}

.clamp {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.sub-container1 .location {
  font-size: 0.8rem;
  color: #bf9f65;
}
.sub-container1 .role {
  font-size: 0.9rem;
  text-transform: capitalize;
}

.product-container-section .box_Product1 .Rs-1 {
  font-size: 1rem;
  font-weight: 400;
}

.product-container-section .box_Product1 button {
  border-radius: 50px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 700;
  font-size: 1.4rem;
  background-color: #bf9f65;
  padding: 1vmin 3.5vmin;
  margin-bottom: -3vmin;
}

@media (max-width: 480px) {
  .product-container-section .box_Product1 {
    border-radius: 25px;
    width: 45vmin;
    text-align: center;
    margin-bottom: 4vmin;
  }

  .productyoumaylike {
    height: 450px;
  }

  .product-container-section .box_Product1 img {
    border-radius: 25px;
  }

  .product-container-section .box_Product1 .icn_Product {
    font-size: 1rem;
    padding: 1.8vmin;
    margin-top: -5vmin;
  }

  .product-container-section .box_Product1 .green-1 {
    font-weight: 700;
    font-size: 0.75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
  }

  .product-container-section .box_Product1 .chennai {
    font-weight: 400;
    font-size: 0.6rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
  }

  .product-container-section .box_Product1 .Rs-1 {
    font-size: 1rem;
    font-weight: 0.75rem;
  }

  .product-container-section .box_Product1 button {
    font-weight: 700;
    font-size: 1rem;
    padding: 1vmin 3.5vmin;
    margin-bottom: -4vmin;
  }
}

/* *********NewProduct**************** */
.newpeoductback {
  background: linear-gradient(
    90deg,
    #eeeeec3d 0%,
    rgba(108, 79, 55, 0.8) 26.33%,
    rgba(108, 79, 55, 0.8) 100%
  );
  border-top-right-radius: 200px;
  border-bottom-right-radius: 200px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  position: relative;
}

.prdtitle {
  margin-top: 5.5rem !important;
  font-size: 22px !important;
  font-weight: 700;
}

@media (max-width: 900px) {
  .newpeoductback {
    background: linear-gradient(
      180deg,
      #eeeeec3d 0%,
      rgba(108, 79, 55, 0.8) 26.33%,
      rgba(108, 79, 55, 0.8) 100%
    );
    border-radius: 0;
  }

  .newprdrw {
    position: relative;
    bottom: 0px !important;
  }

  .newprdcrd .phn {
    cursor: pointer;
    background: rgba(96, 50, 0, 1);

    border: 3px solid white !important;
    position: relative;
    top: -40px;
    color: white;
    font-size: 40px !important;
  }

  .prdtitle {
    font-size: 19px !important;
  }
}

.addfrmmain {
  width: 10px;
}

.newprdcrd {
  border-radius: 10px;
  width: 30rem;
  height: 20rem;
  margin: 2rem 0;
}
.newprdcrd-content {
  background-color: #eee;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.newprdcrd .phn {
  background: #39ab68;
  cursor: pointer;
  border: 4px solid white;
  position: relative;
  top: -20px;
  color: white;
  font-size: 50px;
  margin-bottom: -35px;
}

.newprdbtn {
  background: #bf9f65;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  color: #fff;
}

.newprdrw {
  position: relative;
  bottom: 40px;
}

.newprdround {
  height: 260px;
  width: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 9px solid white;
  line-height: 40px;
  background: rgba(108, 79, 55, 1);
  cursor: pointer;
  box-shadow: 15px 20px 19px 0px rgba(0, 0, 0, 0.25);
}

.addfrm {
  background: rgba(108, 79, 55, 1);
  color: white;
  font-size: 40px;
  font-weight: 500;
  border-radius: 30px;
  float: inline-end;
  position: absolute;

  left: -40px;
}

/* *********************profilr section********************** */

.main_Profiles {
  justify-content: center;
  align-items: center;
  padding: 0px 11rem;
}

.main_Profiles .component-container1 {
  background-color: #f5f1e8;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  border-radius: 40px;
  height: 14rem;
  width: 15rem;
}

.main_Profiles .component-container1 .img {
  width: 5rem;
  height: 5rem;
  border: 4px solid #cecece;
  border-radius: 100%;
  background: transparent;
  position: absolute;
  top: -2rem;
}

.main_Profiles .component-container1 .sub-container1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.main_Profiles .component-container1 .p1 {
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
}

.main_Profiles .component-container1 .p2 {
  font-size: 0.9rem;
  font-weight: 700;
}

.main_Profiles .component-container1 .p2 span {
  font-weight: 500;
}

.main_Profiles .component-container1 .sub-container2 {
  position: absolute;
  bottom: 0;
  background-color: var(--darkbrown);
  border-radius: 40px;
  width: 100%;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main_Profiles .component-container1 .sub-container2 .p3 {
  font-size: 1.1rem;
  font-weight: 700;
}

.main_Profiles .component-container1 .phone-icon {
  background-color: #39ab68;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  /* new */
  cursor: pointer;
  width: 3rem;
  height: 3rem;
}

.countersale {
  position: absolute;
  z-index: 100;
  top: 5%;
  left: 50%;
}

.countesale-body {
  position: absolute;
  z-index: 100;
  top: 69%;
  background-color: #fff;
  left: 22%;
  width: 17rem;
  border-radius: 10px;
  padding: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.countesale-image {
  width: 100%;
  object-fit: cover;
  height: 20rem;
}
.counterslae-title {
  font-size: 1rem;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  margin-left: 0.8rem;
  max-width: 220px;
}
.counterslae-prize {
  color: #818181;
}
.selprize {
  color: #000;
  font-size: 2rem;
  font-weight: 600;
}
.pricrtype {
  font-size: 0.8rem;
  text-transform: capitalize;
  margin-bottom: 0;
  color: #bf9f65;
  font-weight: 500;
}

.old {
  padding-top: 5px;
  padding-bottom: 0;
}

.counterslae-btn {
  top: 5px;
  background: #f94646;
  border: none;
  font-weight: 500;
  color: #fff;
  border-radius: 19px;
  font-size: 0.8rem;
  padding: 1px 10px;
  position: absolute;
  z-index: 10;
  right: 22%;
}

.discountnew {
  position: absolute;
  z-index: 100;
  top: -11%;
  right: -10%;
  width: 8rem;
  height: 7rem;
  background-color: #bf9f65;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 600;
  aspect-ratio: 1;
  clip-path: polygon(
    100% 50%,
    78.98% 57.76%,
    93.3% 75%,
    71.21% 71.21%,
    75% 93.3%,
    57.76% 78.98%,
    50% 100%,
    42.24% 78.98%,
    25% 93.3%,
    28.79% 71.21%,
    6.7% 75%,
    21.02% 57.76%,
    0% 50%,
    21.02% 42.24%,
    6.7% 25%,
    28.79% 28.79%,
    25% 6.7%,
    42.24% 21.02%,
    50% 0%,
    57.76% 21.02%,
    75% 6.7%,
    71.21% 28.79%,
    93.3% 25%,
    78.98% 42.24%
  );
}

.stateimg {
  height: 100px;
  width: 100px;
  /* border-radius: 50px; */
}

@media (max-width: 768px) {
  .main_Profiles .component-container1 {
    margin-top: 2rem;
    border-radius: 25px;
    height: 11rem;
    width: 10rem;
  }

  .main_Profiles .component-container1 .img {
    width: 4rem;
    height: 4rem;
  }

  .sub-container1 .chennai {
    font-size: 0.8rem;
    max-width: 120px;
  }
  .sub-container1 .location {
    font-size: 0.6rem;
  }
  .sub-container1 .role {
    font-size: 0.7rem;
    text-transform: capitalize;
  }
  .main_Profiles .component-container1 .sub-container1 {
    line-height: 1.2;
    font-size: 0.8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
  }

  .main_Profiles .component-container1 .p1 {
    font-size: 1rem;
    font-weight: 700;
  }

  .main_Profiles .component-container1 .p2 {
    font-size: 1rem;
    font-weight: 600;
  }

  .main_Profiles .component-container1 .p2 span {
    font-weight: 500;
  }

  .main_Profiles .component-container1 .sub-container2 {
    border-radius: 25px;
  }

  .main_Profiles .component-container1 .sub-container2 .p3 {
    font-size: 1rem;
    font-weight: 700;
  }

  .main_Profiles .component-container1 .phone-icon {
    font-size: 0.9rem;
  }
}

/* **************flashsale************* */
@media (max-width: 900px) {
  .flashpeoductback {
    background: linear-gradient(
      180deg,
      #eeeeec3d 0%,
      rgba(108, 79, 55, 0.8) 26.33%,
      rgba(108, 79, 55, 0.8) 100%
    ) !important;
    border-radius: 0 !important;
  }
}

.ims {
  border-radius: 30px 30px 30px 30px !important;
}

.flashpeoductback {
  background: linear-gradient(
    90deg,
    #eeeeec3d 0%,
    rgba(108, 79, 55, 0.8) 26.33%,
    rgba(108, 79, 55, 0.8) 100%
  );
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 200px;
  border-bottom-left-radius: 200px;
  position: relative;
}

.flashprdround {
  background: url("../image/home/image-removebg-preview\ \(7\)\ 1\ \(1\).png");
  background-size: cover;
  height: 260px;
  width: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 9px solid white;
  line-height: 40px;
  /* background: rgba(108, 79, 55, 1); */
  cursor: pointer;
  box-shadow: 15px 20px 19px 0px rgba(0, 0, 0, 0.25);
}

/* *****************state************* */
/*************** blogs *******************/
.blog-main-container-1 .tabs {
  background-color: #bf9f65;
  color: white;
  font-size: x-large;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  width: max-content;
  margin-top: 10vh;
}

.blog-main-container-1 .tab {
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  border-radius: 100px;
  padding: 3vh 5vw;
}

.blog-main-container-1 .tab.active {
  background-color: #bf9f65;
}

.blog-main-container-1 .sub-div1 {
  box-shadow: 8px 8px 14px 0px #00000040;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.blog-main-container-1 .sub-div2 {
  box-shadow: 8px 8px 14px 0px #00000040;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.blog-main-container-1 .blog_btn {
  background-color: #bf9f65;
  box-shadow: 8px 8px 14px 0px #00000040;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  padding: 8px 10px;
  margin: 0 0 8px 8px;
  max-width: max-content;
}

.blog-main-container-1 .sub-div1,
.blog-main-container-1 .sub-div2 {
  width: 20vw;
  border-radius: 30px;
  height: 43vh;
}

.blog-main-container-1 .sub-div3,
.blog-main-container-1 .sub-div4 {
  box-shadow: 8px 8px 14px 0px #00000040;
  border-radius: 30px;
  width: 20vw;
  height: 43vh;
}

.blog-main-container-1 .sub_blog p {
  color: #bf9f65;
  font-weight: bold;
  line-height: 1.1;
}

.blog-main-container-1 .sub_blog img {
  max-width: 100%;
  max-height: 100%;
}

.blog-main-container-1 .sub_blog span {
  color: black;
  font-weight: normal;
  font-size: 13.5px;
}

@media (max-width: 768px) {
  .blog-main-container-1 .blog_main {
    flex-direction: column;
  }

  .blog-main-container-1 .sub_blog {
    flex-direction: row !important;
  }

  .blog-main-container-1 .second_row {
    flex-direction: column !important;
  }

  .blog-main-container-1 .sub-div1,
  .blog-main-container-1 .sub-div2,
  .blog-main-container-1 .sub-div3,
  .blog-main-container-1 .sub-div4 {
    width: 100%;
  }

  .blog-main-container-1 .sub-div3,
  .blog-main-container-1 .sub-div4 {
    margin-top: 20px;
  }
}
/******************* flashsale **********************/
.fals-salescard {
  height: 310px;
}

@media (max-width: 768px){
  .fals-salescard {
    height: 280px;
  }
}
/************ Requirement **************/

.custom-container {
  /* background-image: url(../image/home/images/Woddybg.png); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  padding: 0vmin 5vmin;
  /* gap: 5vmin; */
  /* flex-wrap: wrap; */
}

.custom-container .custom-container-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1vmin;
  /* flex-wrap: wrap; */
}

.custom-container .main_col_1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-container h1 {
  font-size: 2.4rem;
  text-align: center;
}

.custom-container .custom-leftSection {
  background-color: #fcf2e4;
  border: 1px solid #fcf2e4;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: end;
  padding: 2vmin 0 0 3vw;
  gap: 3vmin;
}

.custom-container .steps {
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 3vmin;
  margin-bottom: 4vmin;
}

.custom-container .step {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 1.5vmin;
}

.custom-container .icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5vw;
  background: #bf9f65;
  border-radius: 100%;
  box-shadow: 0px 4px 4px 0px #00000040;
  color: #fbf1e6;
  font-size: 3.5rem;
}

.custom-container .para1 {
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  line-height: 1;
}

.custom-container .Man-image {
  width: 20vw;
}

.custom-container .main_col_2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-container .custom-form {
  background: #fcf2e4;
  box-shadow: 16px 9px 35px 0px #00000040;
  border-radius: 100px 0;
  color: #0000;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  gap: 1.6vw;
  overflow: hidden;
}

.custom-container .col_1 {
  width: 100%;
}

.custom-container .right-h2 {
  text-align: center;
  font-weight: 800;
  margin: 4vw 2vw 0 2vw;
  color: #000;
}

.custom-container .col_2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-container .custom-form-input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gap: 1vmin; */
  width: 80%;
}

.custom-container .custom-form-input-container .custom-input-group {
  width: 100%;
}

.custom-container .custom-input {
  width: 100%;
  border: 1.5px solid #a5a5a5;
  border-radius: 50px;
  background: transparent;
  color: #000;
  padding: 0.9vw 0 0.9vw 1.5vw;
}

.custom-container .custom-input:focus {
  color: #000;
  border: 1.5px solid #a5a5a5;
}

.custom-container .form-control {
  border: 1.5px solid #a5a5a5;
}

.custom-container .form-control:focus {
  box-shadow: none !important;
  border-color: #a5a5a5 !important;
}

.custom-container .custom-input::placeholder {
  color: #000;
  font-size: 1.3rem;
  font-weight: bold;
}

.custom-container .col_3 {
  width: 100%;
}

.custom-container .submit-button {
  background: #bf9f65;
  border: 1px solid #9b9b9b;
  box-shadow: 16px 9px 35px rgba(0, 0, 0, 0.25);
  border-radius: 100px 0;
  width: 100%;
  height: 5vw;
  font-weight: 700;
  font-size: 1.5rem;
}

.custom-container .submit-button:hover {
  background: #bf9f65;
  border: 1px solid #9b9b9b;
}

@media (max-width: 1200px) {
  .custom-container {
    background: none;
  }

  .custom-container .custom-container-row {
    gap: 6vmin;
  }

  .custom-container .main_col_1 {
    width: 80%;
  }

  .custom-container .main_conatiner {
    gap: 2.2vmin;
    width: 95%;
  }

  .custom-container h1 {
    font-size: 2.4rem;
  }

  .custom-container .custom-leftSection {
    background-color: #fff1e5;
    padding: 6vmin 6vmin;
    gap: 2vmin;
    border-radius: 40px;
    width: 100%;
  }

  .custom-container .steps {
    gap: 6vmin;
    margin-bottom: 0;
  }

  .custom-container .step {
    gap: 2vmin;
  }

  .custom-container .icon-box {
    padding: 7vmin;
    font-size: 3rem;
  }

  .custom-container .para1 {
    font-size: 1.2rem;
  }

  .custom-container .Man-image {
    display: none;
  }

  /* .custom-container .main_col_2{
    width: 80%;
  } */

  .custom-container .custom-form {
    gap: 7vmin;
    width: 95%;
    border-radius: 100px 0;
  }

  .custom-container .right-h2 {
    font-size: 2.5rem;
    margin: 10vmin 7vmin 0 7vmin;
  }

  .custom-container .custom-form-input-container {
    gap: 2vmin;
  }

  .custom-container .custom-input {
    /* width: 80vmin; */
    height: 9.5vmin;
    padding-left: 4vmin;
  }

  .custom-container .custom-input::placeholder {
    font-size: 1.4rem;
  }

  .custom-container .submit-button {
    border-radius: 100px 0;
    height: 12vmin;
    font-size: 1.8rem;
  }
}

@media (max-width: 768px) {
  .custom-container .steps {
    gap: 3vmin;
    margin-bottom: 0;
  }

  .img1 {
    height: 150px !important;
  }

  .product_detail {
    height: 12vh;
    font-size: 12px !important;
  }

  .dealership-oppo-container
    .dealership-oppo-sub-container
    .dealership-oppo-box
    .dealership-oppo-img-box {
    display: flex;
    justify-content: center;
    align-items: end;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    /* width: 166px !important; */
    /*  */
    width: 215px !important;
    height: 265px !important;
    overflow: hidden;
  }

  .dealerapply {
    background: #573c26;
    border: 2px solid #bc9b80 !important;
    font-size: 14px !important;
    color: white;
    border-radius: 30px;
  }

  .custom-container .step {
    gap: 2vmin;
  }

  .custom-container .icon-box {
    padding: 6vmin;
    font-size: 3rem;
  }

  .custom-container .para1 {
    font-size: 1rem;
  }

  .custom-container .right-h2 {
    font-size: 2rem;
    margin: 10vmin 7vmin 0 7vmin;
  }

  .custom-container .custom-input {
    width: 100% !important;
  }
}

@media (min-width: 1000px) and (max-width: 1199px) {
  .dealership-oppo-container
    .dealership-oppo-sub-container
    .dealership-oppo-box
    .dealership-oppo-img-box {
    width: 215px !important;
  }

  .addoppoutnitybtn {
    bottom: 9%;
    left: 7%;
  }
}

@media (min-width: 360px) and (max-width: 768px) {
  .ctimg {
    height: 100px !important;
    width: 110px !important;
    border-radius: 10%;
    /* box-shadow: 8px 4px 17px 0px rgba(0, 0, 0, 0.25); */
  }

  .searchBox {
    top: 115px !important;
  }
  .addviewtbn {
    float: right;
    margin-right: 6px;
  }
  .flashsale-title {
    padding-left: 1rem;
  }
  .filtershowbtn {
    z-index: 1000;
  }
  .icon-brown-list h2 {
    font-size: 1rem;
  }

  .viewlbtn button{
    padding: 4px 4px;
    font-size: 1rem;
  }
  .address {
    font-size: 0.8rem;
  }
  .icon svg {
    font-size: 13px;
  }
  .react-stars span {
    font-size: 13px !important;
  }
  .product-review .desp p {
    font-size: 10px;
  }
  .product-review .top .name img {
    width: 50px !important;
    height: 50px !important;
  }
  .product-review .top .name h6 {
    font-size: 14px;
  }
  .call-button {
    gap: 10px;
  }
  .supplier-detail .box li.left ul.inner {
    flex-direction: row;
    gap: 0px;
  }
  .supplier-detail .box li.left img {
    width: 5em;
    height: 5rem;
  }
  .inner .image {
    margin-bottom: 16rem;
  }
  .fs-6 {
    font-size: 0.8rem !important;
    line-height: 1.2 !important;
    margin-top: 10px;
  }

  .categoriesback {
    padding-bottom: 1rem !important;
  }
  .heading {
    font-size: 1.3rem;
  }

  .newprdcrd {
    width: 20rem;
    margin-left: 12px;
  }
  .newprdcrd-content .card-title {
    font-size: 1rem;
  }
  .show-counter {
    bottom: 2%;
    width: 100%;
    left: 1%;
  }
  .show-counter .countdown {
    width: 40px;
  }

  .show-counter .countdown-link {
    font-size: 1rem !important;
  }

  .countdown > p {
    font-size: 0.8rem;
    padding: 0px;
  }
  .show-counter .countdown > span {
    font-size: 7px;
  }
  .flashbtncall {
    width: 2.5rem;
    height: 2.5rem;
    top: 56%;
    right: 13px;
  }
  .counterslae-btn {
    font-size: 0.6rem;
    padding: 0px 7px;
    position: absolute;
    z-index: 10;
    right: 4px;
  }
  .countesale-body {
    left: 10%;
  }
  .dealership-oppo-container .product-card .product-details .product-name {
    max-width: 150px !important;
  }
  .dealership-oppo-container .product-card .image-container {
    height: 45% !important;
    padding: 11px;
  }
  .dealership-oppo-container .product-card {
    margin-right: 13px;
  }
  .dealership-oppo-container .product-card .top-productbtn {
    font-size: 0.8rem;
  }
  .dealership-oppo-container .product-card .product-details {
    align-items: flex-start;
  }

  .topproduct-card {
    margin-right: 12px;
    height: 300px !important;
  }
  .topproduct-card .product-details h5 {
    font-size: 1rem;
    max-width: 170px;
  }
  .discountnew {
    top: -7%;
    right: -4%;
    width: 4rem;
    height: 4rem;
    font-size: 1rem;
  }
  .main_Profiles {
    padding: 0px 2rem;
  }

  .card-body-city {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .image-container {
    height: 50% !important;
  }
  .top-productbtn {
    width: auto;
    padding: 10px 24px !important;
    margin-top: 10px;
  }
  .btncall {
    /* top: -5px !important; */
    right: -20px !important;
    width: 2.5rem !important;
    height: 2.5rem !important;
  }
  .topcard-rating {
    margin-left: 0 !important;
  }

  .dealership-banner {
    height: 170px;
  }
  .addoppoutnitybtn {
    padding: 0.7rem 1.2rem;
    font-size: 1rem;
    bottom: 9%;
    left: 5%;
  }
}
@media (max-width: 480px) {
  .custom-container {
    background: none;
  }

  /* .custom-container .main_conatiner {
    display: flex;
    justify-content: center;
    align-items: center; 
    gap: 2.5vmin;
    width: 100%;
  } */

  .custom-container h1 {
    font-size: 1.2rem;
  }

  .custom-container .custom-leftSection {
    background-color: #fff1e5;
    padding: 6vmin 6vmin;
    gap: 2vmin;
    border-radius: 25px;
    width: 100%;
  }

  .custom-container .steps {
    gap: 4vmin;
    margin-bottom: 0;
  }

  .custom-container .step {
    gap: 2.5vmin;
  }

  .custom-container .icon-box {
    padding: 5.5vmin;
    font-size: 2.5rem;
  }

  .custom-container .para1 {
    font-size: 0.7rem;
  }

  .custom-container .Man-image {
    display: none;
  }

  .custom-container .custom-form {
    gap: 6vmin;
    width: 95%;
    border-radius: 65px 0;
  }

  .custom-container .right-h2 {
    font-size: 1rem;
    margin: 10vmin 7vmin 0 7vmin;
  }

  .custom-container .custom-form-input-container {
    gap: 0.2vmin;
  }

  .custom-container .custom-input {
    /* width: 72vmin; */
    height: 9.5vmin;
    padding-left: 4vmin;
  }

  .custom-container .custom-input::placeholder {
    font-size: 0.8rem;
  }

  .custom-container .submit-button {
    border-radius: 65px 0;
    height: 13vmin;
    font-size: 1.1rem;
  }
}

/* Dealership Opportunities */

.dealership-oppo-container .dealership-oppo-sub-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dealership-oppo-container .dealership-oppo-sub-container .dealership-oppo-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
}

.dealership-oppo-container
  .dealership-oppo-sub-container
  .dealership-oppo-box
  .dealership-oppo-img-box {
  display: flex;
  justify-content: center;
  align-items: end;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* width: 300px; */
  /* new */
  width: 270px;
  height: 380px;
  overflow: hidden;
  border-radius: 15px;
  border: 1px solid #eee;
}

.dealership-oppo-container
  .dealership-oppo-sub-container
  .dealership-oppo-box
  .img-1 {
  background-image: url(../../assets/image/home/images/deal-img-1.png);
  border-radius: 70px 0 0 0;
}

.dealership-oppo-container
  .dealership-oppo-sub-container
  .dealership-oppo-box
  .img-2 {
  background-image: url(../../assets/image/home/images/deal-img-2.png);
}

.dealership-oppo-container
  .dealership-oppo-sub-container
  .dealership-oppo-box
  .img-3 {
  background-image: url(../../assets/image/home/images/deal-img-3.png);
}

.dealership-oppo-container
  .dealership-oppo-sub-container
  .dealership-oppo-box
  .img-4 {
  background-image: url(../../assets/image/home/images/deal-img-4.png);
  border-radius: 0 0 70px 0;
}

.dealership-oppo-container
  .dealership-oppo-sub-container
  .dealership-oppo-box
  .dealership-oppo-text-box-1 {
  background: linear-gradient(
    180deg,
    rgba(96, 50, 0, 0) 0%,
    rgb(191, 159, 101) 35.92%,
    #bf9f65 54.5%
  );

  width: 100%;
  padding: 30px 0 30px 50px;
  color: white;
  font-size: 0.8rem;
  line-height: 1.6;
}

.dealership-oppo-container
  .dealership-oppo-sub-container
  .dealership-oppo-box
  .dealership-oppo-text-box-1
  span {
  font-size: 1rem;
  font-weight: 600;
}

.dealership-oppo-container
  .dealership-oppo-sub-container
  .dealership-oppo-box
  .dealership-oppo-text-box-2 {
  color: black;
  font-size: 1.1rem;
  font-weight: 600;
}

.product_detail {
  height: 12vh;
  font-size: 17px;
}

@media (max-width: 1000px) {
  .product_detail {
    height: 11vh;
  }
}

@media (min-width: 750px) and (max-width: 822px) {
  .product_detail {
    height: 10vh;
  }
}

@media (min-width: 820px) and (max-width: 855px) {
  .product_detail {
    height: 9vh;
  }
}

@media (min-width: 1001px) and (max-width: 1030px) {
  .product_detail {
    height: 15vh;
  }
}

/* .discountnew {
  background: url('../image/home/images/Mask\ group.png');
  width: 50px;
  height: 50px;
  background-size: contain;
  color: #bf9f65;
  position: relative;
  top: -350px;
  left: 270px;
  transform: translate(-100%, 0%);
  box-shadow: 8px 4px 17px 0px rgba(0, 0, 0, 0.25);

} */

/* new start */

/* ----- */

.ratingicon {
  color: gold;
}

.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-header {
  cursor: pointer;
  padding: 10px;
}

.dropdown-body {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 10px;
  width: 250px;
}

.dropdown-container:hover .dropdown-body {
  display: block;
}
