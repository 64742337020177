.comparison-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .search-bar {
    position: relative;
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
  }
  
  .search-bar input {
    flex: 1;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .search-bar button {
    padding: 10px 20px;
    background-color: #bf9f65;
    font-family: "Poppins", sans-serif;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .search-bar button:hover {
    background-color: #0056b3;
  }
  
  .suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
    z-index: 10;
  }
  
  .suggestion-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .suggestion-item:hover {
    background-color: #f0f0f0;
  }
  
  .product-grid {
    display: flex;
    justify-content: space-around;
    gap: 20px;
  }
  
  .product-column {
    flex: 1;
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .product-column h2 {
    font-size: 1.5em;
    margin-bottom: 15px;
    text-align: center;
    color: #333;
  }
  
  .product-image {
    width: 100%;
    height: 20rem;
    object-fit: cover;
    width: 100%;
    margin-bottom: 15px;
    border-radius: 4px;
  }
  
  .remove-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #ff4444;
    color: white;
    border: none;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .remove-btn:hover {
    background-color: #cc0000;
  }
  
  .spec-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .spec-table td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .spec-table td:first-child {
    font-weight: bold;
    width: 40%;
    background-color: #eee;
  }
  
  .spec-table td:last-child {
    text-align: center;
  }

  @media (max-width: 569px) {
    .product-grid {
        flex-direction: column;
    }
    .product-image {
        width: 18rem;
    }
   }