.Header {
  /* height: 10rem; */
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* margin: 0 0 30px 0 !important; */
  padding: 0 !important;
}

.Header .Sub_Headere {
  background-color: #f5f1e8;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 40px;
}


.Header .Mid_Navbar {
  background-color: #594230;
  color: white;
  height: 50px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.Header .Header_Link {
  text-decoration: none;
  color: white;
  font-weight: 100;
}

.Header .Navbar {
  background-color: #bf9f65;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}

.Header .main_logo {
  width: 25%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.Header .logo {
  display: flex;
  flex-wrap: wrap;
  max-height: 100%;
  max-width: 100%;
}

.Header .social_icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

}

.Header .callicn {
  color: #ffffff;
  font-size: 20px;
}

.Header .icn {
  background-color: rgb(255, 255, 255);
  border: solid #d7ad8b 3px;
  color: black;
  height: 50px;
  width: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);

}

.Header .Acc_icn {
  background-color: rgb(255, 255, 255) !important;
  border: solid #d7ad8b 3px;
  color: black !important;
  height: 50px;
  width: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.Acc_icn {
  background-color: rgb(255, 255, 255) !important;
  border: solid #d7ad8b 3px;
  color: black !important;
  height: 50px;
  width: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.Header .Dbtn {
  font-size: 15px;
  color: #ffffff;
  display: flex;
  letter-spacing: 2px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #bf9f65;
  font-family: "Poppins", sans-serif;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

}
.form-control:focus {
  box-shadow: none !important;}
.Header .form-control:focus {
  border-color: white !important;
}

.Header .Search_section {
  width: 350px !important;
}


.Header .searchbar {
  display: flex;
  flex-wrap: wrap;
  height: 45px;
  /* background-color: #ab8c71 !important; */
}

.Header .searchbar2 {

  background-color: #fff !important;
  border-radius: 30px !important;
  height: auto !important;
  border: 1px solid #bf9f65;
}

.Header .searchbar2::placeholder {
  color: white !important;
}

.Header .searchbar.form-control {
  color: #000 !important;
  padding-left: 20px;
}

.Header .searchbar::placeholder {
  color: #000 !important;
  font-family: "Poppins", sans-serif !important;
  opacity: 1;
  position: absolute;
  margin-left: 5%;
  letter-spacing: 1px !important;
}

.Header .searchbar.form-control:focus {
  color: #000 !important;
  padding-left: 20px;
}

.Header .searchicn {
  display: flex;
  flex-wrap: wrap;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: #bf9f65;
  color: #ffffff !important;
  border: none;
}
.serchform{
  height: 45px;
  background-color: #ffffff !important;
  border: 1px solid #bf9f65;
}
.Header .navbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 45px;
  width: 200px;
color: #ffffff !important;
  background-color: #bf9f65 !important;
  font-family: "Poppins", sans-serif !important;
  border: solid #ffffff 3px !important;
}

.mblogo {
  background: linear-gradient(356.07deg, rgba(217, 217, 217, 0) 10.9%, rgba(107, 78, 55, 0.542902) 36.79%, #6B4E37 79.16%);

}
.shopfilterposition{
  position: relative;
  bottom: 50px;
}

@media (max-width: 988px) {
  .shopfilterposition{
    position: relative;
    bottom: -11px;
  }
}
@media (max-width: 768px) {
  /* .form-control {
    width: auto !important;
  } */
   
  .shopfilterposition{
    position: relative;
    bottom: -50px;
  }
  .searchBox {
    width: auto !important;
    left: auto !important;

  }

  .btn-close {
    height: auto;
  }

  .Header .logo {
    margin-left: 100px !important;
    padding: 10px !important;
  }

  .Header .contact p {
    font-size: 12px;
  }

  .Header .navbtn {
    font-size: 14px;
    padding: 5px 10px;
  }

  .Header .icn {
    font-size: 1.5rem;
    padding: 10px !important;
    margin-bottom: 5px !important;
  }

  .Header .Acc_icn {
    margin-bottom: 60px !important;
  }

  .Header .Search_section {
    margin-top: 10px;
    margin-left: -40px !important;
  }

  .Header .re_btn {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    margin-left: 10px !important;
  }

  .Header .contact {
    flex-direction: column;
    margin-right: 90px !important;
  }

  .Header .number {
    margin-left: 30px !important;
  }

  .Header .contact p {
    margin-bottom: 5px;
  }

  .Header .d-flex.align-items-center.justify-content-between.w-100.flex-wrap {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 70px !important;
    padding-bottom: 5px !important;
    padding-top: 5px !important;
    gap: -5px !important;
  }

  .Header .social_icons {
    flex-direction: column;
    padding: 5px !important;
  }

  .Header .Search_section {
    order: 2;
    width: 100% !important;
  }
}


