/***************************************************** Header section ****************************************************************/



.AboutUS_Header1 {
    padding-bottom: 3vmin;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5vmin;
}

.onboard_row {
    /* margin-left: 20rem; */
    /* new */
    display: flex;
    justify-content: center;
}

.AboutUS_Header1 .Header_img {
    height: max-content;
    width: max-content;

}

.AboutUS_Header1 .Header_img img {

    width: 40vh;
}

.AboutUS_Header1 .Header_text {
    height: 100%;
    width: 40%;
    font-size: 20px;
    color: black;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}

.AboutUS_Header1 .Header_text h1 {

    font-size: 2.5rem;
    font-weight: 600;
    /* margin-bottom: 2rem; */
}

.AboutUS_Header1 .Header_text p {
    text-align: justify;
    font-size: 1rem;
}


@media (max-width: 480px) {
    .AboutUS_Header1 .Header_text h1 {
        font-size: 2rem;
        font-weight: 600;
        padding: 2vmi;
    }
}


@media (min-width: 768px) {
    .AboutUS_Header1 .Header_text h1 {
        font-size: 2rem;
        font-weight: 600;
    }
}



/* mission section */



.Company_mission_main {
    padding: 20px;
    background-color: #ecd5a9;
    display: flex;
    /* color: black; */
    align-items: center;
    justify-content: center;
    gap: 50px;
}

.Company_mission_main .Company_text {
    color: black;
}

.Company_mission_main .Company_mission_img {
    width: 40vmin;
    height: 40vmin;
    border: 10px solid #ffffff;
    box-shadow: 8px 9px 20px 0px #00000040;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 300px;
    background-image: url(../image/home/images/mission.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.Company_mission_main .arrow {
    width: 20vmin;
}






/**************************************************************** team section ***************************************************************/

.Team_cards {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


}

.Team_main .SubTeam_cards {
    border: solid white 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-shadow: 6px 7px 31px -6px rgba(0, 0, 0, 0.75);

}

.Team_main .man_img {
    height: 65%;
    width: 100%;
    background-color: #f5f1e8;
}

.Team_main .Card_bottum {
    height: 35%;
    width: 100%;
    background-color: #997f6a;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Team_main .Bottum_text {
    width: 85%;
    height: 115%;
    margin-bottom: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #bf9f65;
    color: white;
}

.Team_main .Bottum_text h3 {
    font-size: 23px;
    color: white;
}

.Team_main .Bottum_text h5 {
    font-size: 15px;
    font-weight: 200;
    color: white;
}

.Team_main .Bottum_text p {
    font-size: 0.7rem;
    margin: 0 15px;
}

/* Custom CSS */
@media (max-width: 768px) {
    .Team_main {
        flex-direction: column !important;
        align-items: center;
    }

    .Team_cards {
        width: 100%;
        padding: 10px 0;
        /* Adjust padding for smaller screens */
    }

    .Card_bottum {
        padding: 15px;
        /* Adjust padding inside the card */
    }

    .AboutUS_Header1 .Header_text {
        font-size: 16px;
    }

    .Company_mission_main .Company_mission_img {
        height: 200px;
        width: 200px;
        margin-top: -50px;
    }

    .onboard_row {
        margin-left: 0px;
    }

    .recommondedprdcrd {
        height: 170px;
        width: 170px;
    }
}





/*************************************************************** onboard section ************************************************************/

.onboard_col {
    background: #FFF6EE;
    border: 1px solid #E4E4E4;
    box-shadow: 8px 8px 14px 0px #00000040;
    border-radius: 50px;

}

.onboard_col img {
    width: 100%;
    height: 20vh;
    border: 1px solid #A4A4A4;
    border-radius: 50px;
}

.onboard_col h3 {
    color: #bf9f65;
    font-weight: 700;
    margin-left: 25px;
    width: 90%;
    height: 72px;
    font-size: 1.2rem;
    margin-bottom: 0;
    padding-bottom: 0;


}

.onboard_col p {
    text-align: justify;
    font-size: 0.8rem;
    width: 85%;
    margin-left: 25px;
    line-height: 1.2;
}

.onboard_col button {
    background-color: #bf9f65;
    box-shadow: 8px 8px 14px 0px #00000040;
    border: none;
    border-radius: 40px;
    color: white;
    font-weight: 700;
    margin: 0 0 15px 25px;
    padding: 10px 20px;
}

/* **************Product detail page ***********************/
.smallBanner {
    /* background: url('F:\pplaywoodbazar\src\assets\image\home\image 143.png'); */
    background: url('../image/home/image\ 143.png');
    background-size: cover;
    height: 250px;
}

.smallBann {
    height: 550px;

}
.smallBann  img {
    width: 100%;
    height: 100%;
    /* object-fit: cover; */


}