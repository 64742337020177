/* Container for newsing headlines */

.ticker-div {
  display: flex;
  background: white;
}
.ticker-title {
  background-color: #bf9f65;
  position: relative;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  padding: 16px 15px;
  width: 15%;
  font-size: 17px;
  line-height: 23px;
  z-index: 100;
}
.ticker-mobile-title {
  display: none;
}

.ticker-btn {
  margin-top: 10px;
  background: #f5f1e8;
  border: none;
  border-radius: 8px;
  padding: 10px 12px;
  font-size: 1rem;
  color: #000;
}
.ticker-icon {
  background-color: #39ab68;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  cursor: pointer;
  width: 3rem;
  height: 3rem;
}

.tickerviewlbtn button {
  background: #bf9f65;
  border: none;
  border-radius: 8px;
  padding: 10px 12px;
  font-size: 1rem;
  color: #fff;
}
.news-container {
  width: 100%;
  position: relative;
  border: 1px solid #ddd;
  background-color: #fff;
}

/* Inner content for items */
.news-content {
  display: flex;
  font-family: Arial, sans-serif;
  font-size: 1rem; /* Responsive font size */
  line-height: 20px;
  transition: transform 0.3s ease-in-out;
  /* animation: news-left 30s linear infinite; */
}

/* Individual item styles */
.news-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  padding: 10px;
  margin-right: 10px; /* Space between items */
  background-color: #f5f1e8;
  max-width: 250px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 16px 15px 17px 0;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
  white-space: nowrap;
  position: relative;
  text-overflow: ellipsis;
}

.news-item .prd-name {
  background: #bf9f65 0% 0% no-repeat padding-box;
  padding: 7px 15px;
  border-radius: 40px;
  vertical-align: middle;
  display: inline-block;
  font-weight: bold;
  margin: 0 10px 0 5px;
  font-size: 15px;
  line-height: 18px;
  color: #fff;
}
.news-item .other-labels {
  vertical-align: middle;
  display: inline-block;
  margin: 0 10px;
  font-size: 14px;
  line-height: 18px;
}
.news-item:hover {
  background-color: #f0f0f0;
}
.status-icon {
  top: -11px;
  background: #f94646;
  border: none;
  font-weight: 500;
  color: #fff;
  border-radius: 19px;
  font-size: 0.8rem;
  padding: 1px 10px;
  position: absolute;
  z-index: 10;
  right: -9px;
  animation: blink-bg 1s infinite;
}
/* Done status (green) */
.status-done {
  background-color: #2ecc71;
}

/* Flex behavior for each column */
.news-item span {
  flex-basis: 25%; /* Adjust flex basis for better distribution */
  text-align: center;
}
.description {
  word-wrap: break-word;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .news-item {
    font-size: 0.9rem;
    padding: 4px;
    max-width: 150px;
  }
  .ticker-title {
    display: none;
  }
  .ticker-mobile-title {
    display: block;
    background-color: #bf9f65;
    position: relative;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    padding: 4px;
    font-size: 12px;
    line-height: 23px;
    z-index: 100;
  }
  .ticker-mobile-title .ticker-btn {
    padding: 4px;
    font-size: 12px;
  }
  .news-item span {
    flex-basis: 30%; /* Adjust item width on medium screens */
  }
}

@media (max-width: 480px) {
  .news-item {
    font-size: 0.8rem;
    padding: 2px;
  }

  .news-item span {
    flex-basis: 40%; /* Adjust item width for small screens */
  }
  .ticker-mobile-title {
    display: block;
    background-color: #bf9f65;
    position: relative;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    padding: 4px;
    font-size: 12px;
    line-height: 23px;
    z-index: 100;
  }
  .ticker-mobile-title .ticker-btn {
    padding: 4px;
    font-size: 12px;
  }
}

/* Keyframe for infinite auto-newsing animation */
@keyframes news-left {
  0% {
    transform: translateX(0); /* Starting point (fully visible) */
  }
  50% {
    transform: translateX(-100%); /* Halfway (newsing through items) */
  }
  100% {
    transform: translateX(-300%); /* End of news (fully out of view) */
  }
}

.news-container {
  width: 100%;
  overflow-x: hidden;
  user-select: none; /* Prevents text selection while dragging */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on mobile */
}

.news-content {
  display: flex;
  animation: scroll 20s linear infinite;
  padding: 10px 0;
}

.news-item {
  flex: 0 0 auto;
  margin-right: 20px;
  padding: 10px;
  background: #f5f5f5;
  border-radius: 5px;
  cursor: pointer;
  min-width: 250px; /* Adjust as needed */
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Disable animation on mobile for better drag experience */
@media (max-width: 768px) {
  .news-content {
    animation: none;
  }
}