/* User Ticket Page */

.ticket-faq-container .h4 {
  font-size: 2.3rem;
  font-weight: 600;
  color: black !important;
}

.ticket-faq-container .search-box-button-container {
  background-color: #f5f1e8;
  border: 1.6px solid #000000;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.ticket-faq-container .search-box-button-container input {
  background: transparent;
  border: none;
  outline: none;
}

.ticket-faq-container .search-box-button-container button {
  background-color: #bf9f65;
  color: white;
  border: none;
  border-radius: 100%;
  padding: 0.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  position: absolute;
  right: 0;
}

.ticket-faq-container .create-button-plus-container {
  position: relative;
}

.ticket-faq-container .subsctiption-card-button {
  background-color: #bf9f65 !important;
  /* box-shadow: 0px 4px 4px 0px #00000040; */
  color: white;
  border-radius: 30px;
  /* font-size: 20px; */
  font-weight: 600;
  padding: 8px 15px;
  /* width: 100%; */
}

.ticket-faq-container .subsctiption-card-button:hover {
  box-shadow: 0px 4px 4px 0px #00000040;
}

.ticket-faq-container .hi-plus-icon {
  background-color: #f5f1e8;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 100%;
  color: #7f4e15;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  position: absolute;
  top: -10px;
  right: 0;
  padding: 0.1vw;
  font-size: 1.5rem;
  font-weight: bolder;
}

/* Ticket Table 1 */

.ticket-faq-container .main-datatable-container {
  /* background-color: #f5f1e8; */
  box-shadow: 15px 18px 35px 0px #00000040;
  border-radius: 10px;
  /* border: 1px solid #c4b7b7; */
  /* text-align: center; */
  /* overflow: hidden; */
}

.ticket-faq-container .action-button {
  background-color: #bf9f65;
  /* box-shadow: 0px 4px 4px 0px #00000040; */
  color: white;
  font-weight: 600;
  /* font-size: 17px; */
  /* border: none; */
  /* border-radius: 5px; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  padding: 0.3vw 0.8vw;
  /* margin-left: auto;
  margin-right: auto; */
}

.ticket-faq-container .action-button:hover {
  color: #bf9f65 !important;
  border: #bf9f65 solid 2px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

/* Ticket Table 2 */

.ticket-faq-container .ticket-table {
  background-color: #f5f1e8;
  box-shadow: 15px 18px 35px 0px #00000040;
  border-radius: 10px;
  border: 1px solid #c4b7b7;
  text-align: center;
  overflow: hidden;
}

.ticket-faq-container .ticket-table thead {
  background-color: #bf9f65;
  color: white;
  font-size: 1.3rem;
}

.ticket-faq-container .ticket-table tbody {
  font-weight: 700;
  /* font-size: 17px; */
  border: 1px solid #c4b7b7;
}

.ticket-faq-container .ticket-table th {
  padding: 1vw 0;
}

/* .ticket-faq-container .ticket-table td {
  padding: 0.4vw 0;
} */

.ticket-faq-container .ticket-table button {
  background-color: #bf9f65;
  color: white;
  font-weight: 600;
  /* font-size: 17px; */
  border: none;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 5px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  padding: 0.3vw 0.8vw;
  margin-left: auto;
  margin-right: auto;
}

.ticket-faq-container .ticket-table .td-1 {
  /* padding-right: 61.5vw; */
}

/* FAQ */

.faq-container1 {
  /* background-image: url(../image/home/images/backimg.png);
  background-repeat: no-repeat;
  background-size: cover; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gap: 3vmin; */
}

.faq-container1 h1 {
  text-align: center;
  color: #bf9f65;
  font-weight: bold;
  font-size: 3rem;
}

.faq-container1 .accordion {
  display: flex;
  flex-direction: column;
  gap: 1.5vmin;
}

.faq-container1 .accordion-item {
  border-radius: 45px !important;
  overflow: hidden;
  background-color: #bf9f65;
}

.faq-container1 .accordion-body {
  border-radius: 45px 45px 0 0;
  background-color: #f5f1e8;
  padding: 3vh 4vw 3vh 4vw;
  font-size: 1.06rem;
  font-weight: 500;
  text-align: justify;
}

.faq-container1 .accordion-button {
  background-color: #bf9f65;
  color: white;
  padding: 3.5vh 2vw 3.5vh 4vw;
  font-size: 1.25rem;
  font-weight: 500;
  transition: 0.3s;
}

.faq-container1 .accordion-button:not(.collapsed) {
  box-shadow: none;
  background-color: #bf9f65;
  color: white;
  font-size: 1.37rem;
  transition: 0.3s;
}

.faq-container1 .accordion-button:focus {
  box-shadow: none;
}

.faq-container1 .accordion-button::after {
  filter: grayscale(1) invert(1);
}

.faq-container1 .accordion-header {
}

.faq-container1 .accordion-button:hover {
}

/* Add Ticket Page */

.add-ticket-container .add-ticket-btn-submit {
  background-color: #bf9f65;
}

.add-ticket-container .add-ticket-btn-submit:hover {
  color: #bf9f65 !important;
  border: #bf9f65 solid 2px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

/* View Ticket Page */

.view-ticket-container .view-ticket-btn-submit {
  background-color: #bf9f65;
}

.view-ticket-container .view-ticket-btn-submit:hover {
  color: #bf9f65 !important;
  border: #bf9f65 solid 2px;
  box-shadow: 0px 4px 4px 0px #00000040;
}
