.recentactivities-main-container .profile-section-container {
    background-color: #F5F1E8;
    box-shadow: 15px 18px 35px 0px #00000040;
    padding: 0 !important;
    overflow: hidden;
}

.recentactivities-main-container .profile-section-container .sub-section-1 {
    background-color: #bf9f65;
    box-shadow: 15px 18px 35px 0px #00000040;
    margin: 0 !important;
    padding: 20px;
}

.recentactivities-main-container .profile-section-container .theme-outline-button {
    background: none;
    border: 1px solid #F5F1E8;
    border-radius: 50px;
    color: white;
}

.recentactivities-main-container .profile-section-container .sub-section-2 {
    margin: 0 !important;
    padding: 20px;
    color: black;
}