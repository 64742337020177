/* Reels.css */

.infinite-scroll-component__outerdiv {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.infinite-scroll-component__outerdiv::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}
/* Base Styles */
.scroll-container {
    height: 100vh;
    overflow-y: scroll;
    color: #000;
  }
  
  .video-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .video-wrapper {
    /* width: 100%; */
    width: 250px;
    max-height: 330px;
    aspect-ratio: 9 / 16;
    position: relative;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
  }
  
  .video-element {
    width: 100%;
    height: 100%;
    object-fit: cover;  
  }
  
  /* Play/Pause Button */
  .play-pause-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.6);
    color: white;
    border: none;
    border-radius: 50%;
    padding: 15px;
    font-size: 25px;
    cursor: pointer;
    z-index: 2;
  }
  
  .overlay-actions {
    position: absolute;
    bottom: 20px;
    left: 5px;
    z-index: 1;
    color: white;
    text-align: left;
  }
  
  .overlay-actions h4 {
    font-size: 1rem;
  }
   
  .overlay-actions p {
    font-size: 0.7rem;
  }
  
  .action-buttons {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 10px;
    color: white;
    flex-direction: column;
  }
  
  .like-button,
  .share-button {
    background: none;
    border: none;
    color: white;
    font-size: 0.8rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  .share-button {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .loader {
    text-align: center;
    padding: 20px;
    color: white;
  }
  
  .new-arrival-section {
  position: relative;
}

.slider-container {
  overflow: hidden;
  position: relative;
}

.slider-content {
  display: flex;
  transition: transform 0.3s ease;
}

.slide {
  flex: 0 0 25%; /* This will show 4 videos per slide on desktop */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.slider-navigation button {
  position: absolute;
  top: 50%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.prev-btn {
  left: 10px;
  transform: translateY(-50%);
}

.next-btn {
  right: 10px;
  transform: translateY(-50%);
}

.video-element {
  width: 100%;
  border-radius: 10px;
}



  /* Responsive Styles */
  @media (max-width: 1024px) {
    .video-wrapper {
      max-width: 280px; /* Slightly smaller video size on tablets */
    }
  
    .play-pause-button {
      padding: 12px; /* Smaller play/pause button on tablets */
      font-size: 22px;
    }
  
    .like-button,
    .share-button {
      font-size: 18px; /* Smaller button text on tablets */
    }
  }

  @media (min-width: 1024px) and (max-width: 1368px) {
    .video-wrapper {
      max-width: 280px; /* Slightly smaller video size on tablets */
    }

  }

  
  @media (max-width: 768px) {
    .video-wrapper {
      max-width: 100%; /* Full width for mobile */
      aspect-ratio: 9 / 16; /* Maintain the video aspect ratio */
      max-height: 275px;
      width: 200px;
    }
  
    .play-pause-button {
      padding: 10px; /* Smaller button for mobile */
      font-size: 20px;
    }
  
    .like-button,
    .share-button {
      font-size: 16px; /* Even smaller button text for mobile */
    }
  
    .action-buttons {
      bottom: 15px; /* Reduce the bottom margin for mobile */
      right: 10px; /* Reduce the right margin for mobile */
    }
  
    .overlay-actions {
      bottom: 15px; /* Reduce overlay text size and margin */
      left: 15px;
    }
    .video-wrapper {
      max-height: 400px;
    }
  }
  
  @media (max-width: 480px) {
    .video-container {
        padding: 2rem;
        margin-top: 2rem;
    }
    .play-pause-button {
        padding: 15px;
        font-size: 28px;/* Smaller button on very small screens */
    }
  
    .like-button,
    .share-button {
      font-size: 20px; /* Smallest font size on very small screens */
    }
  
    .action-buttons {
      bottom: 20nm,px; /* Further reduce bottom margin on small devices */
      right: 5px;  /* Further reduce right margin on small devices */
    }
    .video-wrapper {
      max-height: 280px;
      max-width: 75%;
      aspect-ratio: auto;
    }
  }

  .reel-container .new-arrival-slider .swiper-button-next {
    left: 58% !important;
    transform: translateX(-58%) !important;
  }
  
  @media (max-width: 990px) {
    .reel-container .new-arrival-slider .swiper-button-next {
      left: 70% !important;
      transform: translateX(-70%) !important;
    }
  }
  
  @media (max-width: 450px) {
   .reel-container .new-arrival-slider .swiper-button-next {
      left: 80% !important;
      transform: translateX(-80%) !important;
    }
  }
  
  @media (max-width: 990px) {
    .reel-container .new-arrival-slider .swiper-button-prev {
      left: 30% !important;
      transform: translateX(-30%) !important;
    }
  }
  
  @media (max-width: 450px) {
    .reel-container .new-arrival-slider .swiper-button-prev {
      left: 20% !important;
      transform: translateX(-20%) !important;
    }
  }
  
  .reel-container .new-arrival-slider .slider-arrow {
    background: #fff;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    left: 42%;
    transform: translateX(-42%);
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
  }
  
  .reel-container .new-arrival-slider .slider-arrow ion-icon {
    font-size: 2rem;
    color: #222224;
  }
  
  .reel-container .new-arrival-slider .slider-arrow::after {
    content: '';
  }
  
  .reel-container .swiper-pagination {
    position: relative;
    width: 15rem !important;
    bottom: 5rem !important;
    left: 40% !important;
    top: 20px !important;
  
  }

  /* Styling for the bottom navigation arrows */
.bottom-arrow-control {
  position: absolute;
  bottom: 10px; /* Position the arrows 10px from the bottom */
  left: 50%;
  transform: translateX(-50%); /* Center align the arrows */
  z-index: 10;
}

.swiper-button-next-bottom,
.swiper-button-prev-bottom {
  position: relative;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Dark background for visibility */
  color: #fff;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  margin: 0 10px; /* Spacing between arrows */
}

.swiper-button-next-bottom {
  right: 0;
}

.swiper-button-prev-bottom {
  left: 0;
}

.swiper-button-next-bottom:hover,
.swiper-button-prev-bottom:hover {
  background-color: rgba(0, 0, 0, 0.8); /* Darker on hover */
}


/* Media Queries */
@media screen and (max-width: 768px) {
  .slide {
    flex: 0 0 100%; /* On mobile, show 2 videos per slide */
  }
}

/* Navigation buttons below the slider */
.slider-navigation {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.prev-btn, .next-btn {
  background-color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 24px;
  color: #333;
  margin: 0 10px;
  transition: background-color 0.3s ease;
}

.prev-btn:hover, .next-btn:hover {
  background-color: #f0f0f0;
}