.main-product-container .h4 {
  font-size: 2.3rem;
  font-weight: 600;
  color: black !important;
}

.main-product-container .subsctiption-card-button {
  background-color: #bf9f65 !important;
  /* box-shadow: 0px 4px 4px 0px #00000040; */
  color: white;
  border-radius: 100%;
  font-size: 1.5rem;
  font-weight: bolder;
  padding: 0.4vw;
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-product-container .subsctiption-card-button:hover{
  box-shadow: 0px 4px 4px 0px #00000040;
}

.main-product-container .search-box-button-container {
  background-color: #f5f1e8;
  border: 1.6px solid #000000;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.main-product-container .search-box-button-container input {
  background: transparent;
  border: none;
  outline: none;
}

.main-product-container .search-box-button-container button {
  background-color: #bf9f65;
  color: white;
  border: none;
  border-radius: 100%;
  padding: 0.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  position: absolute;
  right: 0;
}

/* .main-product-container .create-button-plus-container {
  position: relative;
} */


/* .main-product-container .hi-plus-icon {
    background-color: #f5f1e8;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 100%;
    color: #7f4e15;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    position: absolute;
    top: -10px;
    right: 0;
    padding: 0.1vw;
    font-size: 1.5rem;
    font-weight: bolder;
  } */

.main-product-container .main-datatable-container {
  /* background-color: #f5f1e8; */
  box-shadow: 15px 18px 35px 0px #00000040;
  border-radius: 10px;
  /* border: 1px solid #c4b7b7; */
  /* text-align: center; */
  /* overflow: hidden; */
}

.main-product-container .action-button {
  background-color: #bf9f65;
  color: white;
  font-weight: 600;
  /* font-size: 17px; */
  /* border: none; */
  box-shadow: 0px 4px 4px 0px #00000040;
  /* border-radius: 5px; */
  /* display: flex;
    justify-content: center;
    align-items: center; */
  padding: 0.3vw 0.8vw;
  /* margin-left: auto;
    margin-right: auto; */
}

.main-product-container .action-button:hover {
  color: #bf9f65 !important;
  border: #bf9f65 solid 2px;
  box-shadow: 0px 4px 4px 0px #00000040;
}
.bg-colour-card{
  background: #F5F1E8 !important;
    box-shadow: 0px 4px 4px 0px #00000040 !important;
    border-radius: 50px !important;
}