.floating-icons .whatsapp {
  position: fixed;
  bottom: 280px;
  left: 2px;
  display: grid;
  flex-direction: column;
  gap: 10px;
  z-index: 1;
}

.floating-icons .quick-enquiry {
  position: fixed;
  bottom: 400px;
  left: -45px;
  display: grid;
  flex-direction: column;
  gap: 10px;
  z-index: 1;
}

.iconz {
  font-size: 3.5rem;
  color: white;
  background-color: #25D366;
  /* Default WhatsApp color */
  padding: 10px;
  border-radius: 50%;
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  position: relative;
}

.whatsapp {
  background-color: #25D366;
  animation: blink 1s infinite;
}

.iconz:hover {
  transform: scale(1.1) rotateY(20deg) rotateX(10deg);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.quick-enquiry {
  padding: 10px;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(-270deg);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  animation: blink-bg 1s infinite;
  /* Animation to blink every 2 seconds */
}

/* Keyframes for the background color blink effect */
@keyframes blink-bg {
  0% {
    background-color: #ffec00; /* Bright Yellow */
  }
  15% {
    background-color: #ff8c00; /* Shining Orange */
  }
  30% {
    background-color: #ff007f; /* Neon Pink */
  }
  45% {
    background-color: #7c00ff; /* Vivid Purple */
  }
  60% {
    background-color: #00ffea; /* Electric Cyan */
  }
  75% {
    background-color: #39ff14; /* Bright Green */
  }
  90% {
    background-color: #ff0000; /* Bright Red */
  }
  100% {
    background-color: #ffec00; /* Bright Yellow */
  }
}


.quick-enquiry-text {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  text-align: center;
  font-weight: bold;
}

@media screen and (max-width:600px) {
  .quick-enquiry {

    padding-top: 8px;
    padding-bottom: 8px;
    bottom: 80px;
    left: -36px !important;
  }

  .floating-icons .quick-enquiry {
    position: fixed;
    bottom: 80px;
    left: -30px;
    display: grid;
    flex-direction: column;
    /* gap: 10px; */
    z-index: 1;
  }

  .quick-enquiry-text {
    color: white;
    text-decoration: none;
    font-size: 0.8rem;
    text-align: center;
    font-weight: bold;
  }
}

@keyframes blink {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.8;
  }
}