.footer {
    /* background-image: url(../image/home/footer.png); */
    background-color: #000;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-wrap: wrap;
    font-size: 13px !important;
    font-weight: 400;

    
  
  }
 .footer .links li a {
    color: white; 
    text-decoration: none;
    transition: color 0.3s ease; 
  }
  
  .footer .links li a:hover {
    color: #ffcc00 !important;
  }
  .contactus .links li a:hover {
    color: #ffcc00 !important;
  }
  .number-hover a:hover {
    color: #ffcc00 !important;
    
}
.elements1 a:hover {
  color: #ffcc00 !important;
}
  @media only screen and (max-width: 991px) {
    .footer {
    /* background-image: url(../image/home/footer.png); */
    background-size: contain;
    background-position: center;
  
  
  }
  
  }
  
  .footer .logo {
    width: 300px;
  }
  
  .icn {
    background-color: rgb(255, 255, 255);
    border: solid #d7ad8b 3px;
    color: black;
    height: 50px;
    width: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  
  }
  .social_icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  
  }
  
  .footer .elements2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .footer .subscribe-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .footer .button-container {
    background-color: transparent;
    color: white;
    border: white solid 0.5px;
    border-radius: 50px;
    padding: 12px 15px;
    font-size: 12.5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 22rem;
    padding-left: 30px;
  }
  
  .footer .button-container input {
    background: transparent;
    color: white;
    outline: none;
    border: none;
    width: 200px;
  }
  
  .footer .button-container input::placeholder {
    color: white;
  }
  
  .footer .button-container button {
    border: white solid 0.5px;
    border-radius: 50px;
    padding: 12px 15px;
    margin: -12px -15px;
    font-weight: bold;
    font-size: 15px;
  }
  
  .footer .copyright {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .footer .copyright div {
    width: max-content;
    font-size: 14px;
  }
  
  .footer .social-icons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .footer .elements2 .list1 {
    display: flex;
    gap: 20px;
  }
  
  .footer .elements2 .list1 a {
    color: #444444;
  }
  
  .footer .elements2 .list1 li {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 10px;
    background-color: white;
    border-radius: 50px;
    width: 35px;
    height: 35px;
  }