@import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../../../node_modules/swiper/swiper-bundle.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

.topbar {
  padding-top: 5px;
  padding-bottom: 5px;
}

.topbar ul {
  display: flex;
  align-items: center;
  gap: 20px;
}

.topbar ul li p,
.topbar ul li a {
  color: #fff;
  margin-bottom: 0;
}

.topbar ul li p {
  font-weight: 500;
}

.megamenheader {
  background: #ddc99e;
  top: 4pc;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  left: 0;
  padding: 20px 0;
  position: absolute;
  transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  width: 100%;
  z-index: 9999;
}

.megamenheader ul.category-list {
  -moz-column-gap: 2pc;
  column-gap: 2pc;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: inherit;
  justify-content: flex-start;
  row-gap: 1pc;
}

.megamenheader ul.category-list li {
  width: 15vw;
}

.supplier-detail {
  margin: 0 4pc;
}

.middle-bar.navbar form.search-bar {
  display: flex;
}

.middle-bar.navbar form.search-bar .form-control {
  background: #eeeeee;
  border: 0;
  border-radius: 0;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 350px;
}

.middle-bar.navbar form.search-bar .form-control input {
  border: 0;
  background: transparent;
  width: 100%;
}

.middle-bar.navbar form.search-bar .icon {
  font-size: 22px;
}

.middle-bar.navbar form.search-bar button.btn {
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.middle-bar.navbar form.search-bar button.btn svg {
  font-size: 26px;
  line-height: 20px;
}

.middle-bar.navbar form.search-bar ul.custom-search-list {
  width: 100%;
}

.middle-bar.navbar form.search-bar ul.custom-search-list-login {
  width: 100%;
}

.middle-bar.navbar form.search-bar ul.custom-search-list label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
}

.middle-bar.navbar form.search-bar ul.custom-search-list-login label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
}

.middle-bar.navbar .navbar-nav {
  justify-content: center;
  gap: 20px;
}

.middle-bar.navbar .navbar-nav .nav-link {
  cursor: pointer;
  font-weight: 500;
  color: #000;
}

.middle-bar.navbar .sign-in-btn button {
  gap: 5px;
  border: 0;
  background-color: transparent;
}

.middle-bar.navbar .sign-in-btn button img {
  width: auto;
  height: 40px;
  -o-object-fit: contain;
  object-fit: contain;
}

.middle-bar.navbar .sign-in-btn .custom-search ul.custom-search-list {
  border-radius: 15px;
  width: -moz-max-content;
  width: max-content;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 1pc;
  padding: 1pc 0;
}

.middle-bar.navbar .sign-in-btn .custom-search ul.custom-search-list-login {
  border-radius: 15px;
  width: -moz-max-content;
  width: max-content;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 1pc;
  padding: 1pc 0;
}

.middle-bar.navbar .sign-in-btn .custom-search ul.custom-search-list li {
  border: 0;
  padding: 8px 30px;
}

.middle-bar.navbar .sign-in-btn .custom-search ul.custom-search-list-login li {
  border: 0;
  padding: 8px 30px;
}

.middle-bar.navbar .sign-in-btn .custom-search ul.custom-search-list li a {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #000;
}

.middle-bar.navbar
  .sign-in-btn
  .custom-search
  ul.custom-search-list-login
  li
  a {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #000;
}

.middle-bar.navbar
  .sign-in-btn
  .custom-search
  ul.custom-search-list
  li.bottom-li {
  border-top: 1px solid lightgray !important;
  margin-top: 1pc;
  padding-top: 1pc !important;
}

.middle-bar.navbar
  .sign-in-btn
  .custom-search
  ul.custom-search-list-login
  li.bottom-li {
  border-top: 1px solid lightgray !important;
  margin-top: 1pc;
  padding-top: 1pc !important;
}

.middle-bar.navbar
  .sign-in-btn
  .custom-search
  ul.custom-search-list
  li.bottom-li
  button.btn {
  font-weight: 500;
  padding: 8px 2pc;
}

.middle-bar.navbar
  .sign-in-btn
  .custom-search
  ul.custom-search-list-login
  li.bottom-li
  button.btn {
  font-weight: 500;
  padding: 8px 2pc;
}

.middle-bar.navbar
  .sign-in-btn
  .custom-search
  ul.custom-search-list
  li.bottom-li
  p.text {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  margin-top: 10px;
}

.middle-bar.navbar
  .sign-in-btn
  .custom-search
  ul.custom-search-list-login
  li.bottom-li
  p.text {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  margin-top: 10px;
}

.middle-bar.navbar .sign-in-btn .custom-search ul.custom-search-list li .icon {
  font-size: 18px;
}

.middle-bar.navbar
  .sign-in-btn
  .custom-search
  ul.custom-search-list-login
  li
  .icon {
  font-size: 18px;
}

.middle-bar.navbar .offcanvas-body {
  justify-content: space-around;
}

.bottom-bar.navbar {
  background-color: #ddc99b;
}

.bottom-bar.navbar .navbar-nav {
  justify-content: center;
  gap: 20px;
}

.bottom-bar.navbar .navbar-nav .nav-link {
  cursor: pointer;
  font-weight: 500;
  color: #000;
}

.bottom-bar.navbar .navbar-nav .nav-link a {
  color: #000;
  position: relative;
}

.bottom-bar.navbar .navbar-nav .nav-link a:hover {
  color: #fff;
}

.bottom-bar.navbar .navbar-nav .nav-link a::after {
  background: #000;
  bottom: -2px;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  width: 0;
}

.bottom-bar.navbar .navbar-nav .nav-link a:hover::after {
  width: 100%;
}

.bottom-bar.navbar .navbar-nav .nav-link:hover .custom-dropdown {
  transition: all 0.5s;
  height: -moz-max-content;
  height: max-content;
  min-height: 25vh;
}

.bottom-bar.navbar .navbar-nav .active a {
  border-bottom: 2px solid #000;
  margin-top: 10px;
}

header .topbar,
header .middle-bar,
header .bottom-bar {
  padding-left: 4pc;
  padding-right: 4pc;
}

.custom-search {
  position: relative;
}

.custom-search .custom-search-list {
  transition: all 0.5s ease-in-out;
  position: absolute;
  left: 0px;
  z-index: 99999 !important;
  width: 250px;
  background: #eae2d0;
  box-shadow: 8px 4px 20px 0px #00000040;
  border-radius: 31px;
  /* new */
  overflow-y: auto;
  max-height: 500px;
}

.custom-search .custom-search-list-login {
  transition: all 0.5s ease-in-out;
  position: absolute;
  left: 0px;
  z-index: 99999 !important;
  width: 250px;
  background: #eae2d0;
  box-shadow: 8px 4px 20px 0px #00000040;
  border-radius: 40px;
  /* new */
  /* height: 75vh;
  overflow-y: auto; */
}

.custom-search .custom-search-list::-webkit-scrollbar {
  width: 8px;
}

.custom-search .custom-search-list-login::-webkit-scrollbar {
  width: 8px;
}

.custom-search .custom-search-list::-webkit-scrollbar-track {
  background: #eae2d0;
  border-radius: 40px;
}

.custom-search .custom-search-list-login::-webkit-scrollbar-track {
  background: #eae2d0;
  border-radius: 40px;
}

.custom-search .custom-search-list::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 2px solid #eae2d0;
}

.custom-search .custom-search-list-login::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 2px solid #eae2d0;
}

.custom-search .custom-search-list-login {
  scrollbar-width: thin;
  scrollbar-color: #888 #eae2d0;
}

.custom-search .custom-search-list li {
  padding: 10px 12px;
}

.custom-search .custom-search-list-login li {
  padding: 10px 12px;
}

.custom-search .custom-search-list li:not(:last-child) {
  border-bottom: 1px solid lightgray;
}

.custom-search .custom-search-list-login li:not(:last-child) {
  border-bottom: 1px solid lightgray;
}

.custom-dropdown {
  position: absolute;
  left: 0;
  width: 100%;
  /* height: 50vh; */
  background: #ddc99b;
  overflow: hidden;
  transform: translateY(16px);
  height: 0;
  transition: all 0.5s;
  z-index: 999;
}

.custom-dropdown-inner {
  padding: 2pc 0;
  height: inherit;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.custom-dropdown ul.category-list {
  height: inherit;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -moz-column-gap: 2pc;
  column-gap: 2pc;
  row-gap: 1pc;
  justify-content: flex-start;
}

.custom-dropdown ul.category-list li {
  width: 15vw !important;
}

.custom-dropdown ul.category-list .link {
  color: #000;
}

.main-banner {
  background-image: url("../images/top_banner.jpeg");
  background-size: cover;
  background-attachment: fixed;
  height: 70vh;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.6);
}

.main-banner .content {
  color: #fff;
  text-align: center;
}

.main-banner .content h1,
.main-banner .content h6 {
  color: #fff;
}

.main-banner .content p.desp {
  margin: 2pc 0;
  font-weight: 600;
}

.main-banner .content ul {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
}

.main-banner .content ul li .icon {
  height: 70px;
  width: 70px;
  margin: 0 auto 1pc;
  border: 1px solid #bf9f65;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 37px;
  border-radius: 50%;
  padding: 10px;
}

.page-banner {
  /* background-image: url("../images/top_banner.jpeg"); */
  background-size: cover;
  background-position: center;
  height: 50vh;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.22);
}

.page-banner .content {
  color: #fff;
  text-align: center;
}

.page-banner .content h1,
.page-banner .content h6 {
  color: #fff;
}

.page-banner .content p.desp {
  margin: 2pc 0 0;
  font-weight: 600;
}

.category {
  margin-right: 10px;
}

.category .box {
  margin: 0 0 30px 10px;
  overflow: hidden;
}

.category .box img {
  height: 276px;
  width: 100%;
  transition: all 0.5s;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.38) 0px 1px 4px;
}

.category .box .text {
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px 5px;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
}

.category .box .text h5 {
  margin: 0;
  color: #000;
  font-size: 20px;
  text-align: center;
}

.product-box {
  position: relative;
  border-radius: 5px;
  border: 1px solid lightgray;
  height: 350px;
  color: #000;
}

.product-box img.img {
  width: 100%;
  border-radius: 2px !important;
  height: 200px;
  -o-object-fit: cover;
  object-fit: cover;
  /* border-radius: 12px; */
  background-color: white;
}

.product-box button.call-btn {
  background-color: #927251;
  height: max-content;
  width: max-content;
  border: 9px solid #fff;
  background-color: #39ab68;

  border: 4px solid #fff;
  color: #fff;
  border-radius: 50%;
  font-size: 27px;
  padding: 5px;
  position: absolute;
  margin-top: -65px;
  margin-left: -30px;
}

.product-box button.call-btn svg {
  transition: all 0.5s ease-in-out;
}

.product-box button.call-btn:hover svg {
  transform: rotate(45deg) scale(0.9);
}

.product-box .content {
  margin-top: 20px;
  text-align: center;
  padding: 1pc 1pc 0;
}

.product-box .content .title{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.product-box .content h6 {
  font-weight: 600;
  font-size: 14px;
}

.product-box .content h6.size {
  margin: 1pc 0;
}

.product-box .content h6.prize {
  margin: 0;
  color: #bf9f65;
}

.flash-sale-box {
  background: #013c6f;
  color: #fff;
  font-family: auto;
  text-align: center;
  padding: 2pc;
  border-radius: 20px;
  height: 100%;
}

.flash-sale-box h1,
.flash-sale-box h4 {
  color: #fff;
  margin: 0;
}

.flash-sale-box .offer {
  margin-top: 2pc;
}

.flash-sale-box .offer h4 {
  font-size: 32px;
}

.flash-sale-box .title {
  background-image: url("../images/decor/sale-bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flash-sale-box .title h1 {
  font-size: 60px;
}

.product-box-2 {
  border: 1px solid lightgray;
  padding: 1pc;
  border-radius: 15px;
  width: 90%;
  background: #fff;
}

.product-box-2 img.img {
  width: 100%;
  height: 300px;
  -o-object-fit: cover;
  object-fit: cover;
}

.product-box-2 h6.title {
  position: absolute;
  bottom: 0;
  left: 5%;
  background: #fff;
  padding: 19px;
  border-radius: 10px 10px 0 0;
  text-align: center;
  width: 90%;
  margin: 0;
  padding-bottom: 0;
  font-size: 15px;
}

.product-box-2 ul.tags {
  position: absolute;
  top: 0;
  right: 0;
}

.product-box-2 ul.tags li {
  background: #f74d57;
  margin: 6px 0;
  padding: 3px 10px;
  border-radius: 5px 0 0 5px;
  color: #fff;
  font-weight: 600;
}

.product-box-2 .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1pc;
  padding: 0 1pc;
}

.product-box-2 .content button.call-btn {
  background: #f74d57;
  color: #fff;
  border: 0;
  width: 60px;
  border-radius: 5px;
  font-size: 22px;
  padding: 5px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-box-2 .content h6 {
  font-size: 15px;
}

.product-box-2 .content h6 .prize {
  font-weight: 600;
}

.product-box-2 .content h6.old .prize {
  text-decoration: line-through;
}

.product-box-2 .content h6.new {
  margin-bottom: 0;
}

.product-box-2 .content h6.new span.prize {
  color: #f74d57;
}

.expired-notice {
  text-align: center;
  padding: 2rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  margin: 0.5rem;
}

.expired-notice > span {
  font-size: 1rem;
  font-weight: bold;
  color: red;
}

.expired-notice > p {
  font-size: 1.5rem;
}

.show-counter {
  padding: 0.5rem;
  font-family: "Poppins", sans-serif;
  width: 68%;
}

.show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  border-radius: 0.25rem;
  text-decoration: none;
  color: #000;
}

.show-counter .countdown {
  line-height: 1.25rem;
  /* new */
  /* padding: 0 0.75rem 0 0.75rem; */
  padding: 0 0.25rem 0 0.45rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown + p {
  color: #000;
}

.show-counter .countdown > span {
  text-transform: uppercase;
  font-size: 10px;
  /* new */
  color: #fff;
  font-weight: 500;
  margin-top: -12px;
}

.show-counter .countdown-link {
  font-size: 2.15rem !important;
}
.find-supplier {
  border: 1px solid lightgray;
  padding: 1pc;
  border-radius: 15px;
  background: #fff;
  text-align: center;
}

.find-supplier img.img {
  border-radius: 10px;
  width: 100%;
  height: 150px;
  -o-object-fit: cover;
  object-fit: cover;
}

.find-supplier h6 {
  font-size: 19px;
  margin: 1pc 0 0;
}

.vender-box {
  text-align: center;
}

.vender-box .inner {
  border: 1px solid lightgray;
  padding: 1pc;
  border-radius: 15px;
  background: #fff;
}

.vender-box img.img {
  border-radius: 10px;
  width: 100%;
  height: 150px;
  -o-object-fit: cover;
  object-fit: cover;
}

.vender-box h6 {
  padding: 0 1pc;
  margin: 1pc 0;
}

.vender-box .btn {
  margin-top: -35px;
}

.clients-box {
  text-align: center;
}

.clients-box img {
  height: 85px;
  width: auto;
  -o-object-fit: contain;
  object-fit: contain;
}

.more-box {
  position: relative;
  height: 100%;
}

.more-box .icon {
  background: #bf9f65;
  color: #fff;
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 44px;
  border-radius: 50%;
  margin: 0 auto 0pc;
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
}

.more-box .content {
  text-align: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1254901961);
  padding: 5pc 2pc 2pc;
  border-radius: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.more-box .content h4 {
  font-weight: 700;
}

.contact-us {
  background-image: url("../images/decor/bg-1.png");
  background-size: 100% 100%;
}

.contact-us .left {
  padding-top: 4pc;
}

.contact-us .left .heading {
  margin-bottom: 4pc;
}

.contact-us .left .list {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  gap: 10px;
}

.contact-us .left .list li {
  width: 33.33%;
  text-align: center;
}

.contact-us .left .list li .icon {
  height: 70px;
  width: 70px;
  margin: 0 auto 1pc;
  border: 1px solid #bf9f65;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 37px;
  border-radius: 50%;
  background-color: #bf9f65;
  padding: 10px;
  color: white;
}

.contact-us .left .list li h6 {
  font-weight: 600;
  margin-bottom: 0;
}

.contact-us .right form.form {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1254901961);
  padding: 2pc;
  border-radius: 15px;
  background-color: #fff;
}

.news-letter {
  background-color: #fff7e9;
}

footer {
  background-color: #000;
}

footer .copyright,
footer .footer {
  position: relative;
  z-index: 6;
}

.footer {
  padding: 10px 0;
  color: rgba(255, 255, 255, 0.5019607843);
}

.footer a {
  color: inherit;
}

.footer .icon:hover,
.footer a:hover {
  color: #bf9f65;
}

.footer .title {
  color: #bf9f65;
  margin-bottom: 1.5pc;
  font-weight: 700;
}

.footer .social-links {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 1pc;
}

.footer .social-links li a {
  height: 40px;
  width: 40px;
  border: 1px solid rgba(255, 255, 255, 0.5019607843);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.footer .social-links li a:hover {
  background-color: #bf9f65;
  color: #fff;
  border-color: #bf9f65;
}

.footer .links li {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.footer .links li:not(:last-child) {
  margin-bottom: 8px;
}

.footer .links li .icon {
  color: #bf9f65;
}

.copyright {
  padding: 10px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1254901961);
  color: #fff;
}

.copyright a {
  color: inherit;
}

.shop-page .product-name {
  display: flex;
  align-items: center;
  gap: 2px;
}

.shop-page .product-name li {
  font-weight: 600;
  font-size: 17px;
}

.shop-page .product-name li .icon {
  font-size: 25px;
}

.searchbar2::before {
  content: url("../image/home/image%20140.png");
  /* content: "ok"; */
}

.shop-filter {
  border: 1px solid lightgray;
  background: #f5f1e8;
  box-shadow: 8px 9px 30px 0px #00000040;
  border-radius: 0px 80px 0px 80px;
}

.shop-filter .accordion-item {
  background-color: white !important;
  border: 0px !important;
}

.shop-filter .accordion-button {
  padding: 10px !important;
  background: #f5f1e8 !important;
  color: black;
  font-weight: 700;
}

.shop-filter .title {
  font-weight: 600;
  margin-bottom: 1pc;
}

.shop-filter .accordion-body {
  position: absolute;
  z-index: 2 !important;
  background: #f5f1e8;
  color: black;
  border-radius: 30px;
}

.shop-filter.accordion-button:focus {
  border: 0px !important;
  border-color: transparent !important;
}

.shop-filter .choosefilter {
  border-radius: 30px;

  margin: 0 !important;
}

.shop-filter .comm-list li {
  margin-bottom: 5px;
}
.shop-filter .comm-list li label {
  font-size: 0.875rem;
}

.shop-filter .box {
  padding: 1pc 0;
}

.shop-filter .box:not(:last-child) {
  border-bottom: 1px solid lightgray;
}

.shop-filter .box:last-child {
  padding-bottom: 0;
}

.shop-filter .box label {
  display: flex;
  align-items: center;
  gap: 7px;
  font-weight: 500;
  color: #57577a;
  cursor: pointer;
}

.shop-filter .inner-list {
  padding-left: 1pc;
}

.shop-filter .list {
  max-height: 250px;
  overflow: hidden;
  overflow-y: scroll;
  padding-right: 10px;
  padding: 10px;
}

.shop-filter .list::-webkit-scrollbar {
  width: 5px;
}

.shop-filter .list::-webkit-scrollbar-thumb {
  background: #bebcbc;
  border-radius: 10px;
}

.shop-filter .list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.shop-filter .price-range {
  display: flex;
  align-items: center;
  gap: 10px;
}

.category-page img {
  width: 100%;
  height: 250px;
  border-radius: 10px;
  margin-bottom: 1pc;
}

.category-page .title {
  line-height: normal;
  font-weight: 600;
}

.category-page .sub-category li {
  font-weight: 500;
  color: #333232;
}

.custom-modal {
  position: relative;
}

.custom-modal .btn-close {
  position: absolute;
  top: 1pc;
  left: 1pc;
  opacity: 0.8;
}

.custom-modal .btn-close.right {
  left: auto;
  right: 1pc;
}

.sign-in-modal {
  text-align: center;
  padding: 3pc;
}

.sign-in-modal img.country-img {
  height: 30px;
  width: 30px;
  -o-object-fit: contain;
  object-fit: contain;
}

/* .sign-in-modal . {
  display: flex;
  align-items: center;
} */

.sign-in-modal .form-control .input {
  font-weight: 600;
  display: flex;
  align-items: center;
  color: #000;
}

.sign-in-modal .form-control .input input {
  font-weight: inherit;
}

.sign-in-modal .custom-search {
  padding-right: 10px;
}

.sign-in-modal .custom-search p.pointer {
  display: flex;
  align-items: center;
}

.sign-in-modal .custom-search-list {
  border-radius: 0;
}

.sign-in-modal .custom-search-list li {
  padding: 4px 10px;
}

.sign-in-modal .custom-search-list li img {
  height: 30px;
  width: 30px;
  -o-object-fit: contain;
  object-fit: contain;
}

/*  */

.sign-in-modal .custom-search-list-login {
  border-radius: 0;
}

.sign-in-modal .custom-search-list-login li {
  padding: 4px 10px;
}

.sign-in-modal .custom-search-list-login li img {
  height: 30px;
  width: 30px;
  -o-object-fit: contain;
  object-fit: contain;
}

/*  */

.sign-in-modal .heading {
  margin: 1pc 0;
}

.product-page ul.mini-img {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1pc;
  justify-content: center;
  margin-top: 1pc;
}

.product-page {
  /* background: #FFF2E2; */
}

.product-page ul.mini-img img {
  height: 100px;
  width: 100px;
  cursor: pointer;
  -o-object-fit: cover;
  object-fit: cover;
  border: 1px solid lightgray;
  padding: 3px;
}

.product-page .big-img {
  overflow: hidden;
  border-radius: 10px;
}

.product-page .big-img:hover img {
  transform: scale(1.5);
  transition: all 1s;
}

.product-page .big-img img {
  height: 400px;
  width: 100%;
  transition: all 1s;
  border-radius: inherit;
}

.product-page .mid ul.info {
  width: 80%;
}

.mid {
  height: 100%;
  width: 100%;
  /* box-shadow: 8px 8px 4px 0px #00000040;37 */
}

.product-page .mid ul.info li {
  padding: 7px 1pc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  border-bottom: 1px solid #ccc;
}

.product-page .mid ul.info li:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

.product-page .mid .desp {
  margin: 1pc 0 10px;
  font-size: 15px;
}

.product-page .mid .desp p {
  line-height: normal;
}

.product-page .mid .sizes ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 10px;
  -moz-column-gap: 10px;
  column-gap: 10px;
}

.product-page .mid .sizes ul li {
  background: rgba(204, 204, 204, 0.3019607843);
  color: #000;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 14px;
  cursor: pointer;
}

.product-page .mid .btns {
  margin: 1pc 0;
  display: flex;
  align-items: center;
  gap: 1pc;
}

.product-page .mid .btns .btn-custom {
  --bs-btn-padding-x: 2pc;
  --bs-btn-padding-y: 13px;
  background: #bf9f65;
  border-radius: 100px !important;
}

.product-page .right {
  background-color: #fff7e9;
  padding: 1pc;
  padding-top: 70px;
}

.product-page .right p,
.product-page .right .list-circle li {
  font-size: 13px;
  line-height: normal;
  font-weight: 500;
  margin-bottom: 0;
  color: #000;
}

.product-page .right .info {
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 15px;
  border-radius: 20px;
  box-shadow: 8px 9px 35px 0px #00000040;
  background: #ffffff;
}

.product-page .right .info .img {
  height: 130px;
  width: 130px;
  -o-object-fit: cover;
  object-fit: cover;
  border: 5px solid #cecece;
  box-shadow: 10px 17px 25px 0px #00000040;
  border-radius: 100px;
  padding: 2px;
  margin-top: -70px;
}

.product-page .right .info .star_img {
  width: 10vmin;
}

.product-page .right .info .supplier-rating {
  display: flex;
  align-items: center;
  gap: 5px;
}

.product-page .right .btn {
  --bs-btn-padding-y: 8px;
  background: #bf9f65;
  border-radius: 100px;
  margin-bottom: -20px;
}

.product-tabs .submit-button {
  background-color: #bf9f65 !important;
}
.product-tabs .tab-description ul.info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.product-tabs .tab-description span {
  font-weight: 400 !important;
}

.product-tabs .tab-description ul.info li {
  padding: 7px 1pc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 300;
  border-bottom: 1px solid;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 600px) {
  .product-tabs .tab-description ul.info li {
    padding: 2vmin 8vw !important;
  }
}

.product-tabs .tab-description ul.info li {
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  padding: 10px;
  font-weight: 300;
  border-bottom: 1px solid;
  display: flex;
  justify-content: space-between;
}

.vlp {
  border-left: 1px solid rgb(0, 0, 0);
  border-right: 1px solid rgb(0, 0, 0);
  height: 50px;
  font-size: 24px;
}

.product-tabs ul.tabs {
  display: flex;
  align-items: center;
  color: rgb(0, 0, 0);
  font-size: x-large;
  font-weight: bold;
}

.supllertabs {
  align-items: center;
  text-align: center;
  color: rgb(0, 0, 0);
  font-size: x-large;
  font-weight: bold;
  padding: 20px;
}

.product-tabs ul.tabs li {
  cursor: pointer;
  padding: 2vh 1vw;
  font-weight: 300 !important;
  padding: 10px;
  color: #000;
  font-size: 17px !important;
}

.product-tabs ul.tabs li.active {
  border-bottom: 2px solid #000;
  font-weight: 400;
}

.product-tabs .tab-inner {
  padding-top: 2pc;
}

.product-tabs .main_col_2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-tabs .custom-form {
  background: #fff2e2;
  color: black !important;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  gap: 1.6vw;
}

.product-tabs .col_1 {
  width: 100%;
}

.product-tabs .right-h2 {
  text-align: center;
  font-weight: 800;
  margin: 4vw 2vw 0 2vw;
  color: black;
}

.product-tabs .col_2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-tabs .custom-form-input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gap: 1vmin; */
  width: 80%;
}

.product-tabs .custom-form-input-container .custom-input-group {
  width: 100%;
}

.product-tabs .custom-input {
  width: 100%;
  border: 1.5px solid #a5a5a5;
  border-radius: 50px;
  background: transparent;
  color: black;
  padding: 0.9vw 0 0.9vw 1.5vw;
}

.product-tabs .custom-input:focus {
  background: transparent;
  color: black;
}

.product-tabs .custom-input::placeholder {
  color: black;
  font-size: 1rem;
  font-weight: bold;
}

.product-tabs .col_3 {
  width: 100%;
}

.product-tabs .submit-button {
  background: #bf9f65;
  border: 1px solid #9b9b9b;
  width: 100%;
  font-weight: 400;
  font-size: 1.3rem;
}

.product-tabs .submit-button:hover {
  background: #bf9f65;
  border: 1px solid #9b9b9b;
}

.product-tabs .tab-inner .desp p {
  margin-bottom: 0;
}

.swiper-button-prev,
.swiper-button-next {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1254901961);
}

.swiper-button-prev:after,
.swiper-button-next:after {
  color: #bf9f65;
  font-size: 25px;
  font-weight: bold;
}

.product-review {
  /* box-shadow: 0px 0px 0px 0px #0000001A; */

  /* box-shadow: 0px 21px 46px 0px #0000001A; */

  /* box-shadow: 0px 83px 83px 0px #00000017; */

  /* box-shadow: 0px 187px 112px 0px #0000000D; */

  box-shadow: 0px 333px 133px 0px #00000003;

  box-shadow: 0px 520px 146px 0px #00000000;

  border-radius: 50px;
  padding: 2pc;
  border: 1px solid transparent;
  background-color: #fff;
  transition: all 0.5s;
}

.product-review:hover {
  transform: scale(0.98);
  transition: all 0.5s;
}

.product-review .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-review .top .name {
  display: flex;
  align-items: center;
  gap: 16px;
}

.product-review .top .name img {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}

.product-review .top .name p {
  font-weight: 600;
}

.product-review .top .name h6 {
  margin: 0;
  font-weight: 600;
  font-size: 19px;
}

.product-review .desp p {
  line-height: normal;
  font-size: 15px;
  margin-top: 10px;
}

.supplier-detail .box li.left img {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  box-shadow: 10px 17px 25px 0px #00000040;
}

.supplier-detail .box li.left ul.inner {
  display: flex;
  align-items: center;
  gap: 1pc;
}

.supplier-detail .box li.left ul.inner ul li {
  display: flex;
  gap: 10px;
  color: #000;
  font-weight: 500;
  margin-bottom: 5px;
}

.supplier-detail .box li.left ul.innner1 .icon-brown-list {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 30px;
}

.supplier-detail .box li.left ul.innner1 span {
  font-size: 32px;
}

.supplier-detail .box li.right ul {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
}

.supplier-detail .box li.right ul a {
  width: 180px;
  justify-content: center;
}

.supplier-product-box img.img {
  height: 285px;
}

.supplier-products .box {
  display: flex;
  gap: 1pc;
  align-items: center;
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1254901961);
  background: #fff;
}

.supplier-products .box .image {
  width: 45%;
  position: relative;
}

.supplier-products .box .image img {
  width: 100%;
  height: 225px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 12px 0 0 12px;
}

.supplier-products .box .image .btn {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 0;
  padding: 11px 20px;
}

.supplier-products .box .content {
  padding: 1pc;
}

.supplier-products .box .content p {
  margin: 0;
  font-size: 15px;
  line-height: normal;
  font-weight: 500;
}

.supplier-products .box .content h5 {
  font-weight: 600;
}

.product-video .image {
  height: 300px;
}

.product-video .image iframe {
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 15px;
}

.product-video .content {
  margin-top: 10px;
}

.product-video .content h5 {
  font-weight: 600;
  margin-bottom: 0;
}

.product-video .content p {
  font-size: 14px;
  line-height: normal;
}

.supplier-profile .box {
  display: flex;
  flex-direction: column;
  margin: 10px;
  height: 200px;
  display: flex;
  gap: 1pc;
  padding: 2pc 1pc;
  border-radius: 10px;
  border: 1px solid transparent;
  transition: all 0.5s;
  background: #fff7ee;
  box-shadow: 6px 4px 20px 0px #00000040;
}

.supplier-profile .box .icon {
  height: 100px;
  width: 100px;
  background: #ebccac;
  box-shadow: 0px 4px 4px 0px #00000040 inset;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -70px;
  margin-left: -40px;
  padding: 20px;
}

.supplier-profile .box .icon img {
  width: 50px;
}

.supplier-profile .box .content h5 {
  font-weight: 600;
}

.supplier-profile .box .content p {
  font-size: 13px;
  font-weight: 500;
}

.supplier-profile .box:hover {
  transform: scale(0.99);
  border-color: #bf9f65;
  transition: all 0.5s;
}

.supplier-tabs li {
  display: flex;
  align-items: center;
  gap: 10px;
}

.subscription-container {
  background-color: "#FAFBFD";
}

.subscription-container .subsctiption-heading {
  font-size: 40px;
  margin: 50px 0px 18px 0px;
  color: black;
  font-weight: 600;
}

.subscription-container .subscription-description {
  font-size: 15px;
  width: 100%;
  margin: 0px 0px 30px 0px;
  color: rgba(0, 0, 0, 0.575);
  font-weight: 400;
}

.subscription-container .subscription-card {
  width: 33%;
  margin: 20px 1.5%;
  min-height: auto;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 30px; */
  position: relative;
  padding-bottom: 20px;
}

.subscription-container .subscription-card .subscription-card-heading {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 30px;
}

.subscription-container .subscription-card .subscription-card-price {
  font-size: 21px;
  font-weight: 700;
  color: #ddc99b;
}

.subscription-container .subscription-card .subscription-card-days {
  font-size: 20px;
  font-weight: 500;
  color: #ddc99b;
}

.subscription-container .subscription-card .subscription-card-description {
  margin-top: 20px;
}

.subscription-container .subscription-card .subscription-card-message-list {
  width: 90%;
  margin-top: 30px;
}

.subscription-container .subscription-card .subscription-card-message-list li {
  list-style: circle;
  width: 100%;
}

.subscription-container .subscription-card .subsctiption-card-button {
  position: absolute;
  bottom: 30px;
}

.profile-section-container-left .profile-section-container-left-info {
  background: #f5f1e8;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 50px;
}

.profile-section-container-text {
  color: #bf9f65;
  font-weight: 600;
}

.moto-bg {
  background-image: url(../image/home/images/Woddybg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.moto-bg li {
  list-style-type: disc;
  color: #000;
}

.profile-section-container {
  /* box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.123); */
  padding: 15px !important;
  border-radius: 20px !important;
  margin-bottom: 10px;
  max-width: 100%;
}

.profile-section-container-2 {
  max-width: 100%;
  background: rgba(245, 241, 232, 1);
  box-shadow: 12px 14px 35px 0px rgba(0, 0, 0, 0.25);
}

.subscribe-syatus .porfilebox {
  width: 100%;
  background-color: #bf9f65;
  box-shadow: 8px 6px 32px 0px #00000040;
  color: white;
  border-radius: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px !important;
}

.subscribe-syatus .porfilebox h4 {
  width: 100%;
  font-size: 15px;
  background: #f5f1e8;
  text-align: center;
  border-radius: 100px;
  font-weight: 600;
  color: black;
  padding: 17px 14px;
  height: 60px !important;
  margin-bottom: -5px !important;
}

.subscribe-syatus .porfilebox h5 {
  font-size: 20px;
  color: black;
  font-weight: 300;
}

.subscribe-syatus .porfilebox h6 {
  font-size: 50px;
  color: white;
  margin: 60px 0 20px 0;
  font-weight: 500;
}

.profile-section-Heading {
  display: flex;
  align-items: center;
  height: fit-content;
  padding: 15px 0;
  margin: auto 0;
  font-size: 1.4rem;
  font-weight: 600;
  color: white;
  border-radius: 60px 0px 0px 60px;
  background: linear-gradient(
    90deg,
    #bf9f65 0%,
    #bf9f65 66.96%,
    rgba(96, 50, 0, 0) 100%
  );
  padding-left: 50px;
}

.productyoulike {
  height: 500px;
  /* margin-bottom: -50px; */
}

@media (max-width: 480px) {
  .profile-section-container-left .profile-section-Heading {
    font-size: 1.1rem !important;
    padding: 10px 0;
  }

  .productyoulike {
    height: 400px;
  }

  .profile-section-container-left .profile-section-Heading-icn {
    font-size: 0.75rem !important;
  }

  .profile-section-container-left .theme-outline-button {
    font-size: 1.1rem !important;
    padding: 5px !important;
  }

  .profile-section-container-left2 {
    padding: 0 20px !important;
  }
}

.profile-section-Sub-Heading {
  font-size: 18px;
  font-weight: 600;
}

.border-right {
  border-right: solid 1px rgba(128, 128, 128, 0.237) !important;
  height: inherit;
  width: 1px;
}

.theme-outline-button {
  /* border: solid 1px #b08218;
  color: #b08218; */
  /* width: -moz-fit-content; */
  border: none;
  color: #bf9f65;
  padding: 12px;
  font-size: 17px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: linear-gradient(0deg, #f5f1e8, #f5f1e8),
    linear-gradient(0deg, #f5f1e8, #f5f1e8);
  box-shadow: 0px 4px 4px 0px #00000040;
  /* border-radius: 100%; */
  border-radius: 50px;
}

.contact-btn {
  color: #b08218;
  width: -moz-fit-content;
  width: 100%;
  cursor: pointer;
  height: auto;
  background: linear-gradient(
    90deg,
    #bf9f65 0%,
    rgba(122, 85, 55, 0.86) 69.37%,
    rgba(178, 129, 89, 0.238856) 92.34%,
    rgba(200, 146, 101, 0) 100%
  );
  padding: 10px 30px;

  border-radius: 25px;
}

.counter_absol {
  position: absolute;
  top: 60px;
  right: 15px;
}

.show-counter {
  position: absolute;
  bottom: 10px;
  left: 15%;
  /* transform: translate(-50%, -50%); */
}

.flashbtncall {
  background-color: #39ab68;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: 55%;
  right: 23%;
  color: #fff;
  border: 3px solid #fff;
}
.overlyasper {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.623);
}

.countdown {
  padding: 0px;
  color: #fff !important;
  background: #bf9f65;
  border-radius: 5px;
}

.countdown > p {
  margin: 0;
  padding: 10px;
  padding-bottom: 0px;
  text-align: center;
  border: 1px solid transparent;
  font-size: 1.5rem;
}
.fals-salescard .product-details {
  align-items: flex-start;
}
.fals-salescard .image-container {
  height: 50%;
}

..blogImage {
  height: 350px;
  width: 100%;
  -o-object-fit: fill;
  object-fit: fill;
}

.main-blog {
  background-color: #f9f9f9;
}

.main-blog .al_center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-blog .al_center .blog_bx_left {
  width: 48%;
  height: 380px;
  box-shadow: 0 1px 6px -1px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
}

.main-blog .al_center .blog_bx_left .blog__img {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  display: flex;
  margin: auto;
  border-radius: 12px;
}

.main-blog .al_center .blog_bx_right {
  width: 48%;
}

.main-blog .al_center .blog_bx_right .blog-headingtext {
  font-size: 45px;
  color: #000;
  font-weight: 500;
}

.main-blog .al_center .blog_bx_right p {
  font-size: 19px;
  line-height: 1.5;
  margin-bottom: 15px;
}

.main-blog .al_center .blog_bx_right .writer_name,
.main-blog .al_center .blog_bx_right .content_date {
  color: #919191;
  font-size: 15px;
}

.main-blog .container-fluid {
  padding: 0px 9pc;
}

.blog_listing {
  margin-bottom: 50px;
}

.blog_listing .blog_listing_img {
  overflow: hidden;
  border-radius: 10px;
}

.blog_listing .blog_listing_img img {
  width: 100%;
  height: 190px;
  border-radius: 10px;
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.7s;
  transition-property: all;
}

.blog_listing .blog_listing_img:hover img {
  filter: brightness(0.6);
  transition: 1s all;
  transform: scale(1.2);
}

.blog_listing .list_content_blog h6 {
  margin: 20px 0;
  color: #fcaf17;
}

.blog_listing .list_content_blog h4 {
  font-size: 22px;
  line-height: 30px;
  color: #4a4a4a;
  font-weight: 600;
}

.blog_listing .list_content_blog p {
  margin: 0;
}

.blog_listing .list_content_blog .btn.blog_readmore {
  color: #000;
  font-weight: 600;
  padding-left: 0;
  font-size: 16px;
}

.blog_listing .list_content_blog .btn.blog_readmore:hover {
  color: #fcaf17 !important;
}

.blog_listing .list_content_blog .btn.blog_readmore:hover svg {
  font-size: 22px;
  transition: all 0.2s;
}

.blog_listing .list_content_blog .btn.blog_readmore svg {
  transition: all 0.2s;
}

.blog-box .btn {
  letter-spacing: 1px;
}

.blog-box h4 {
  margin: 14px 0 5px;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.5;
}

.blog-box h4 a {
  color: #000000;
}

.blog-box .image {
  height: 275px;
}

.blog-box .image img {
  border-radius: 15px;
}

.blog-box ul.tags {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
}

.blog-box ul.tags li {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  color: #4d4d4d;
}

.active-tab {
  background-color: #ddc99b;
  color: white;
  text-align: center;
  padding: 20px 0px 10px 0px;
  cursor: pointer;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.in-active {
  background-color: white;
  color: black;
  text-align: center;
  padding: 20px 0px 10px 0px;
  cursor: pointer;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid #ccc;
}

.dataContainer h4 {
  margin-top: 25px;
}

.dataContainer ul li {
  list-style: circle;
  margin-bottom: 15px;
}

.sliderimg {
  height: 50vh;
  width: 100%;
}

.team_img img {
  height: 350px;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  margin-bottom: 25px;
  border-radius: 5px;
}

.centercentr {
  text-align: center;
}

.middlebar_new .custom-search {
  position: relative;
}

.middlebar_new form.search-bar .form-control {
  background: #eeeeee;
  border: 0;
  border-radius: 0;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 350px;
}

.middlebar_new form.search-bar {
  display: flex;
}

.middlebar_new form.search-bar .pointer {
  display: flex;
  gap: 10px;
}

.middlebar_new form.search-bar .form-control input {
  border: 0;
  background: transparent;
  width: 100%;
}

.middlebar_new .middle-bar .navbar-nav {
  justify-content: center;
  gap: 20px;
}

.topheader_scroll {
  overflow-x: scroll;
}

.topheader_scroll::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

.topheader_scroll::-webkit-scrollbar-track {
  background: #ddc99e;
}

/* Handle on hover */
.topheader_scroll::-webkit-scrollbar-thumb:hover {
  background: #bea777;
  border-radius: 10px;
}

.webkitnone.navbar-nav {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.middlebar_new .nav .nav-link {
  cursor: pointer;
  font-weight: 500;
  color: #000;
  position: relative;
}

.middlebar_new .nav .nav-link span {
  position: absolute;
  top: 3px;
  width: 16px;
  height: 16px;
  background-color: #b08229;
  color: #fff;
  left: 25px;
  border-radius: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
}

.middlebar_new .sign-in-btn button {
  gap: 5px;
  border: 0;
  background-color: transparent;
}

.middlebar_new .sign-in-btn button img {
  width: auto;
  height: 40px;
  -o-object-fit: contain;
  object-fit: contain;
}

.middlebar_new {
  padding: 20px 4pc;
}

.middlebar_new .sign-in-btn .custom-search ul.custom-search-list {
  border-radius: 15px;
  width: -moz-max-content;
  width: max-content;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 1pc;
  padding: 1pc 0;
  width: 230px;
}

.custom-search .custom-search-list li a {
  display: flex;
  gap: 10px;
}

.custom-search .custom-search-list li .promo_del_opp {
  display: flex;
  gap: 3px !important;
}

/*  */
.middlebar_new .sign-in-btn .custom-search ul.custom-search-list-login {
  border-radius: 15px;
  width: -moz-max-content;
  width: max-content;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 1pc;
  padding: 1pc 0;
  width: 230px;
}

.custom-search .custom-search-list-login li a {
  display: flex;
  gap: 10px;
}

/*  */

.searchBox.listsearch a p {
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(225, 225, 225, 0.3411764706);
}

.supplierlist li {
  list-style: none;
}

.btn-outline-custom {
  color: #bf9f65;
  border-color: #bf9f65;
}

.btn-outline-custom:hover {
  color: white;
  background-color: #bf9f65;
}

.address {
  font-size: 17px;
  color: #4d4c4c;
  font-weight: 300;
  line-height: 21px;
}

.thankucard {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 6pc 0;
  text-align: center;
  margin: 5pc 0;
  border-radius: 5px;
}

.resendtp {
  color: #000;
  font-weight: 600;
  margin-top: 10px;
  cursor: pointer;
  text-decoration: underline;
}

.page-banner.supplierbanner {
  box-shadow: 8px 9px 35px 0px #00000040;
}

@media (max-width: 1400px) and (min-width: 1367px) {
  .bottom-bar.navbar .navbar-nav .nav-link a {
    font-size: 15px;
  }
}

@media (max-width: 1600px) and (min-width: 992px) {
  .middlebar_new form.search-bar .form-control {
    min-width: auto;
  }

  .subscription-container .subsctiption-heading {
    font-size: 30px;
  }

  .middlebar_new {
    padding: 20px 1pc;
  }

  .flex_row {
    align-items: center;
  }

  .middlebar_new .nav .nav-link {
    padding: 0px 12px;
  }

  .middlebar_new .sign-in-btn button img {
    height: 30px;
  }
}

@media (max-width: 1367px) and (min-width: 992px) {
  .page-banner .content h1 {
    font-size: 23px;
  }

  .bottom-bar.navbar .navbar-nav .nav-link a {
    font-size: 13px;
  }

  .middlebar_new .nav .nav-link {
    font-size: 13px;
  }

  .page-banner .content p.desp {
    font-size: 13px;
  }

  .main-logo {
    height: auto !important;
    width: 100% !important;
  }

  .middle-bar.navbar form.search-bar .form-control {
    min-width: auto;
  }

  header .topbar,
  header .middle-bar,
  header .bottom-bar {
    padding-left: 1pc;
    padding-right: 1pc;
  }

  .bottom-bar {
    padding-left: 1pc;
    padding-right: 1pc;
  }

  .bottom-bar.navbar .navbar-nav .nav-link {
    font-size: 13px;
  }

  .bottom-bar.navbar .navbar-nav {
    gap: 0;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .searchBox {
    top: 120px;
  }

  .middle-bar.navbar form.search-bar button.btn {
    font-size: 12px;
  }

  .middle-bar.navbar .navbar-nav .nav-link {
    font-size: 13px;
  }

  .middle-bar.navbar form.search-bar .form-control {
    min-width: auto;
  }

  .mobile-logo {
    width: 30%;
  }

  .footer a {
    font-size: 13px;
  }

  .footer .title {
    font-size: 16px;
  }

  .footer .social-links {
    gap: 5px;
  }
}

@media (max-width: 992px) {
  .category .box .text {
    background: #fff;
  }

  .blog_listing {
    margin-bottom: 10px !important;
  }

  .category .box .text h5 {
    color: #000 !important;
  }

  .mobileheader {
    background-color: #fff;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    padding: 8px 0;
  }

  .bottom-bar.navbar {
    background-color: unset !important;
  }

  .mobileheader .mobile-logo {
    width: 90%;
  }

  .custom-dropdown ul.category-list li {
    width: 100% !important;
  }

  .page-banner.supplierbanner {
    margin: 0 10px;
  }

  .subscription-container .subsctiption-heading {
    font-size: 19px;
    margin-bottom: 10px;
  }

  .subscription-container .subscription-description {
    width: 100%;
    font-size: 15px;
  }

  .subscription-container .subscription-card {
    width: 47%;
    margin: 0 !important;
  }

  .main-blog .container-fluid {
    padding: 0px 15px;
  }

  .product-page .mid ul.info {
    width: 100%;
  }

  .middlebar_new .sign-in-btn .custom-search ul.custom-search-list li a {
    display: flex;
    gap: 10px;
    font-size: 14px;
  }

  .custom-search .custom-search-list li {
    padding: 7px 5px;
  }

  /*  */

  .middlebar_new .sign-in-btn .custom-search ul.custom-search-list-login li a {
    display: flex;
    gap: 10px;
    font-size: 14px;
  }

  .custom-search .custom-search-list-login li {
    padding: 7px 5px;
  }

  /*  */

  .topnavigation .container-fluid {
    flex-direction: row-reverse;
    flex-wrap: unset;
  }

  .topnavigation .navbar-toggler {
    border: none;
    box-shadow: none !important;
  }

  .reverheadermobile {
    display: flex;
    flex-direction: column-reverse;
  }

  .middlebody {
    display: unset;
  }

  .middlebar_new {
    padding: 10px 0px;
  }

  .middlebar_new .sign-in-btn button img {
    height: 30px;
  }

  .middlebar_new form.search-bar .form-control {
    min-width: auto;
    height: 36px;
  }

  .middlebar_new .sign-in-btn button {
    gap: 0;
    font-size: 12px;
  }

  .mobile_botm_border.navbar-nav .nav-link {
    border-bottom: 1px solid #dee2e6;
    padding: 8px 10px;
    color: #000;
  }

  .custom-dropdown ul.category-list .link {
    color: #8d8d8d;
  }

  .custom-dropdown ul.category-list {
    gap: 0;
  }

  .mainnavigation .nav-link {
    padding: 0;
  }

  .mainnavigation .offcanvas-body {
    padding: 0;
  }

  .mainnavigation .accordion-button.collapsed {
    border-radius: 0 !important;
    color: #989898;
    padding: 12px 10px !important;
  }

  .mainnavigation .accordion-button {
    background-color: #fff !important;
    color: #000;
    padding: 10px;
  }

  .mainnavigation .accordion-button:focus {
    box-shadow: none;
  }

  .mainnavigation .accordion-body {
    padding: 1px 0;
  }

  .custom-dropdown {
    position: unset;
    height: unset;
    overflow: unset;
    background-color: unset;
    transform: unset;
  }

  .custom-dropdown-inner {
    padding: 0;
    height: unset;
    display: unset;
  }

  .custom-dropdown-inner li {
    padding: 5px 0;
    font-size: 13px;
    border-bottom: 1px solid rgba(152, 152, 152, 0.2705882353);
  }

  .custom-dropdown-inner li:last-child {
    border-bottom: none;
  }

  .btnmodalfiexed {
    position: fixed;
    bottom: 0;
    width: 100%;
    margin: auto;
    background-color: #ddc99b;
    padding: 10px;
    color: #000;
    z-index: 99;
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    left: 0;
    right: 0;
    padding: 0;
  }

  .filtershowbtn {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #ddc99b;
    padding: 10px;
    color: #000;
    z-index: 99;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }

  header .topbar,
  header .middle-bar,
  header .bottom-bar {
    padding-left: 4px;
    padding-right: 4px;
  }

  .heading.bottom-line {
    width: auto !important;
    font-size: 23px;
  }

  footer .main-logo {
    width: 100% !important;
  }

  .contact-us .right h3 {
    font-size: 20px;
    margin-bottom: 25px;
  }

  .ptb-80 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
}

@media (max-width: 768px) {
  .vlp {
    border-left: 1px solid rgb(0, 0, 0);
    border-right: 1px solid rgb(0, 0, 0);
    height: 30px;
    font-size: 15px;
  }

  .supllertabs {
    align-items: center;
    text-align: center;
    color: rgb(0, 0, 0);
    font-weight: bold;
    background: #f5f1e8;
    box-shadow: 8px 9px 30px 0px #00000040;
    border-radius: 0px 40px 0px 40px;
    padding: 15px;
  }

  .editprofilemt0 .pt-4,
  .editprofilemt0 .mt-4 {
    padding-top: 10px !important;
  }

  .editprofilemt0 .my-4 {
    margin-top: 10px !important;
    margin-bottom: 0 !important;
  }

  .border-right {
    display: none;
  }

  .flash-sale-box {
    padding: 1pc;
    margin-bottom: 1pc;
  }

  .expired-notice {
    padding: 0.4rem;
  }

  .flash-sale-box .title {
    height: 143px;
  }

  .flash-sale-box .title h1 {
    font-size: 30px;
  }

  .flash-sale-box .offer h4 {
    font-size: 18px;
  }

  .supplier-detail {
    margin: 0 1pc;
  }

  .sign-in-modal {
    padding: 20px 1pc;
  }

  .custom-modal .btn-close {
    top: 2px;
    height: 20px;
  }

  p{
    font-size: 12px;
  }

  .product-tabs ul.tabs {
    gap: 1px;
  }

  .supplermartop {
    margin-top: 15px;
  }

  .supplier-detail .box li.right ul a {
    width: 100%;
  }

  .supplier-detail .box li.left ul.inner {
    display: flex;
    align-items: center;
    gap: 1pc;
    flex-direction: column-reverse;
  }

  .supplier-detail .box li.left img {
    width: 100%;
    height: 230px;
  }

  .profile-section-container {
    margin-bottom: 5px;
  }

  .profile-section-container h4 {
    font-size: 19px;
    margin-bottom: 0;
  }

  .profile-section-container .porfilebox h4 {
    font-size: 18px;
    text-align: center;
  }

  .profile-section-Heading {
    font-size: 21px;
    font-weight: 500;
    margin-bottom: 15px;
  }

  .middlebar_new .sign-in-btn .custom-search ul.custom-search-list {
    padding: 10px 15px;
  }

  .middlebar_new .sign-in-btn .custom-search ul.custom-search-list-login {
    padding: 10px 15px;
  }

  .overlayflowscroll.row {
    overflow-x: scroll;
    flex-wrap: nowrap;
  }

  .overlayflowscroll .col-lg-2.col-12.active-tab {
    max-width: 170px;
    flex: 1;
  }

  .overlayflowscroll .col-lg-2.col-12.in-active {
    max-width: 170px;
    flex: 1;
  }

  .regsiter_user .right h3,
  .regsiter_user .right h4 {
    font-size: 19px;
  }

  .mobilebootm {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .regsiter_user .m-3 {
    margin: unset !important;
    padding-bottom: 25px;
  }

  .news-letter h1.heading.mb-0.text-end.brown {
    text-align: center !important;
    margin-bottom: 15px !important;
  }

  .shop-page h4 {
    font-size: 17px;
  }

  .page-banner .content p.desp {
    display: none;
  }

  .custom-search .yellow-bg {
    padding: 0px 7px;
    font-size: 10px;
  }

  .title-section.with-btn .btn {
    transform: translateY(92%) !important;
    font-size: 10px;
    padding: 4px 14px;
  }

  .middlebar_new .sign-in-btn .custom-search ul.custom-search-list {
    left: 50%;
    transform: translateX(-95%);
  }

  .middlebar_new .sign-in-btn .custom-search ul.custom-search-list-login {
    left: 50%;
    transform: translateX(-95%);
  }

  .middlebar_new .sign-in-btn button img {
    height: 20px;
  }

  .tab-description ul.info .text-dark {
    text-align: right;
  }

  .tab-description ul.info {
    font-size: 14px;
  }

  .product-page .mid ul.info li {
    font-size: 14px;
  }

  .product-page .mid ul.info li .text-dark {
    text-align: right;
  }

  .product-page .mid ul.info {
    width: 100%;
  }

  .product-page .mid {
    padding-top: 60px;
  }

  .product-page .big-img img {
    height: 240px;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .middle-bar.navbar form.search-bar .form-control {
    min-width: auto;
  }

  .flex_row {
    align-items: center;
  }

  .flex_row.d-flex.mobile_right {
    justify-content: end;
    padding-right: 10px;
  }

  .mobile-logo {
    width: 50%;
  }

  .sliderimg {
    height: 50vh;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .product-box {
    margin-top: 10px;
    min-height: 340px;
  }

  .products .row {
    overflow: scroll;
    flex-wrap: nowrap;
  }

  .products .col-12 {
    flex: 1 1;
    min-width: 307px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 30px;
    height: 30px;
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 15px;
  }

  .subscription-container .subsctiption-heading {
    font-size: 19px;
    margin-bottom: 10px;
  }

  .subscription-container .subscription-description {
    width: 100%;
    font-size: 15px;
  }

  .subscription-container .subscription-card {
    width: 47%;
  }
}

@media (max-width: 567px) {
  .row.flex_deraction_row {
    flex-direction: column;
  }

  .list_content_blog h6 {
    font-size: 14px;
  }

  .shop-filter .list {
    overflow-x: scroll;
  }

  .category .box img {
    height: 190px !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .category .box {
    overflow: visible;
    height: unset !important;
    margin-bottom: 20px;
    margin-left: 0;
  }

  .category .box .text {
    position: unset;
  }

  .d-flex.flexunset {
    display: unset !important;
  }

  .product-page .mid ul.info li,
  .product-tabs .tab-description ul.info li {
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }

  .buttoncontainer {
    display: flex;
  }

  .buttoncontainer button {
    display: flex;
    font-size: 0.7rem;
  }

  .footer .title {
    margin-bottom: 0;
    padding: 0px 0;
    margin-top: 22px;
    margin-bottom: 12px;
  }

  .searchBox {
    width: 70%;
  }

  .shoppagepading .page-banner {
    height: 20vh;
  }

  .supplier-detail .box li.right ul {
    align-items: unset;
  }

  .product-tabs ul.tabs li {
    font-size: 15px;
  }

  .product-tabs ul.tabs {
    gap: 10px;
  }

  .theme-outline-button {
    padding: 5px 4px;
    font-size: 11px !important;
  }

  .mysubcripterfong {
    font-size: 13px;
    margin-bottom: 0;
  }

  .subscription-container .subsctiption-heading {
    font-size: 19px;
    margin-bottom: 10px;
  }

  .subscription-container .subscription-card {
    width: 100%;
  }

  .subscription-container .subscription-description {
    width: 88%;
    font-size: 12px;
  }

  .mobiletoppadding {
    margin-top: 23px;
  }

  .mobiletoppadding .profile-section-Heading {
    font-size: 18px;
  }

  .profile-section-container {
    min-height: auto;
    overflow: hidden;
  }

  .profile-section-container {
    padding: 30px 12px !important;
  }

  .more-box .content {
    padding: 5pc 1pc 2pc;
  }

  .page-banner .content h1 {
    font-size: 23px;
  }

  .page-banner {
    height: 28vh;
  }

  .middle-bar.navbar form.search-bar button.btn {
    font-size: 12px;
  }

  .middle-bar.navbar .navbar-nav {
    gap: 0;
  }

  .middle-bar.navbar .sign-in-btn button {
    font-size: 14px;
  }

  .middle-bar.navbar .sign-in-btn button img {
    width: 45px;
  }

  .middle-bar.navbar .navbar-nav .nav-link {
    font-size: 13px;
    padding: 0px;
    margin-right: 14px;
  }

  .navbar {
    padding: 0;
  }

  header .topbar,
  header .middle-bar,
  header .bottom-bar {
    padding-left: 0;
    padding-right: 0;
  }

  .bottom-bar.navbar {
    padding: 8px 0;
  }

  .sliderimg {
    height: 20vh;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .category .box {
    height: 292px;
  }

  .category .box .text h5 {
    font-size: 17px;
    font-weight: 500;
    color: #fff;
    padding: 10px 0;
  }

  .px-4pc {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .product-box {
    padding: 0;
  }

  .vender-box .inner {
    border: 1px solid lightgray;
    padding: 5px;
    border-radius: 15px;
    background: #fff;
  }

  .vender-box img.img {
    height: 94px;
  }

  .vender-box .btn.btn-yellow {
    font-size: 12px;
  }

  .find-supplier h6 {
    font-size: 14px;
  }

  .contact-us .left {
    padding-top: 0;
    padding-bottom: 60px;
  }

  .contact-us .left .heading {
    margin-bottom: 2pc;
  }

  .find-supplier img.img {
    height: 101px;
  }

  .find-supplier {
    padding: 10px;
  }

  .vender-box h6 {
    font-size: 14px;
    padding: 3px 0;
  }

  .product-box-2 img.img {
    height: 200px;
  }

  .product-box-2 ul.tags li {
    font-size: 13px;
  }

  .product-box-2 {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .category .box {
    height: 175px;
  }

  .mobileserach.custom-search .custom-search-list {
    width: 226px;
    left: 20px;
    top: 40px;
  }

  .mobileserach.custom-search .custom-search-list-login {
    width: 226px;
    left: 20px;
    top: 40px;
  }

  .profile-section-container .porfilebox h4 {
    font-size: 13px;
  }

  .contact-us .right form.form {
    padding: 18px 2px;
  }

  .title-section.with-btn .btn {
    display: none;
  }

  .category .box .text {
    padding: 6px 10px;
  }

  .category .box .text a {
    font-size: 12px;
  }
}

@media (max-width: 768px) and (min-width: 567px) {
  .px-4pc {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .middlebar_new .nav .nav-link {
    padding: 0px 2px;
  }

  .shoppagepading.px-4pc {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
  color: #797979;
  line-height: 1.7em;
  font-weight: 400;
  background: #fff;
  overflow-x: hidden;
}

.mb-80 {
  /* margin-bottom: 80px !important; */
  margin-bottom: 40px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.ptb-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.pt-80 {
  padding-top: 80px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.px-4pc {
  padding-left: 4pc;
  padding-right: 4pc;
}

.btn:focus {
  box-shadow: none;
}

.btn-custom {
  font-weight: 500;
  --bs-btn-padding-x: 1pc;
}

.btn-hover {
  position: relative;
  overflow: hidden;
}

.btn-hover:before {
  position: absolute;
  background: rgba(255, 255, 255, 0.5019607843);
  height: 100%;
  width: 100%;
  top: 0;
  left: 100%;
  content: "";
  border-radius: 3px;
  transition: all 0.5s;
}

.btn-hover:hover:before {
  transition: all 0.5s;
  left: 0;
  transform: translateX(-100%);
}

.btn-brown {
  background-color: #bf9f65;
  color: #fff;
  transition: all 0.5s;
}

.btn-brown:hover {
  background-color: #000 !important;
  color: #fff !important;
  transition: all 0.5s;
}

.btn-black {
  background-color: #363636;
  color: #fff;
}

.btn-black:hover {
  background-color: #363636 !important;
  color: #fff !important;
}

.btn-yellow {
  background-color: #bf9f65;
  box-shadow: 8px 9px 35px 0px #00000040;

  color: #fff;
  border-width: 2px;
  border-radius: 30px;
}

.btn-yellow:hover {
  background-color: #f5f1e8 !important;
  color: #bf9f65 !important;

  border-color: #bf9f65;
}

.emailic {
  font-size: 37px;
  padding: 5px;
}

.wtsp {
  background-color: #39ab68;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 37px;
  rotate: -51deg;
  padding: 5px;
}

.wtsp1 {
  background-color: #39ab68;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 37px;
  padding: 2px;
}

.btn-link-yellow {
  color: #bf9f65;
  border-bottom: #bf9f65 solid 1px;
  padding: 0px;
  margin: 0px;
  width: -moz-max-content;
  width: max-content;
  border-radius: 0px !important;
}

.btn-link-yellow:hover {
  color: #bf9f65 !important;
  border-bottom-color: #bf9f65;
}

.btn.with-icon {
  display: flex;
  align-items: center;
  gap: 5px;
}

.poppin {
  font-family: "Poppins", sans-serif !important;
}

.brown {
  color: #bf9f65 !important;
}

.brown-bg {
  background-color: #bf9f65 !important;
}

.yellow {
  color: #bf9f65 !important;
}

.yellow-bg {
  background-color: #bf9f65 !important;
}

.gray-bg {
  background-color: #ffffff !important;
}

.fs-15 {
  font-size: 15px;
}

.list-circle li {
  list-style-type: circle;
  list-style-position: inside;
}

.flex-1 {
  flex: 1;
}

.heading {
  font-weight: 700;
}

.heading.bottom-line {
  width: -moz-max-content;
  width: max-content;
  padding-bottom: 1pc;
  background-image: url("../images/decor/line.png");
  background-repeat: no-repeat;
  background-size: 250px;
  background-position: center bottom;
  font-weight: 500;
}

.title-section.with-btn {
  text-align: center;
  position: relative;
}

.title-section.with-btn .heading {
  margin-bottom: 0;
}

.title-section.with-btn .btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.title-section.with-btn .btn.btn-custom {
  --bs-btn-padding-x: 1.5pc;
  --bs-btn-padding-y: 7px;
}

p {
  line-height: 1.4em;
}

/* p:last-child {
  margin-bottom: 0;
} */

.img-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.img-contain {
  -o-object-fit: contain;
  object-fit: contain;
}

.img-position-top {
  -o-object-position: top;
  object-position: top;
}

.img-fill {
  -o-object-fit: fill;
  object-fit: fill;
}

a {
  text-decoration: none;
  color: #000;
  transition: all 0.5s;
}

a:hover {
  transition: all 0.5s;
  color: #bf9f65;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
}

.btn-sm {
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.main-logo {
  height: 61px;
  width: auto;
  -o-object-fit: contain;
  object-fit: contain;
  transition: all 0.5s;
  margin-top: 21px;
}

.translateX {
  transform: translateX(0) !important;
}

.pointer {
  cursor: pointer;
}

.overlay {
  position: relative;
}

.overlay:before {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  content: "";
  z-index: 66;
  pointer-events: none;
}
.custom-search .custom-search-list {
  background-color: #fff !important;
}

#scrollUp {
  position: fixed;
  right: 1pc;
  bottom: 1pc;
  opacity: 0;
}

#scrollUp .scroll-inner {
  height: 45px;
  width: 45px;
  background: #f16331;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #fff;
  font-size: 22px;
  cursor: pointer;
}

#call-us {
  position: fixed;
  right: 2pc;
  bottom: 1pc;
  filter: drop-shadow(0px 0px 6px gray);
  z-index: 9999;
  opacity: 0;
}

#call-us:before {
  content: "";
  position: absolute;
  border: 2px solid #fff;
  bottom: 0;
  right: 0;
  transform: translate(-2px, -2px);
  height: 15px;
  width: 15px;
  background: #128c7e;
  z-index: 0;
}

#call-us .call-us-inner {
  height: 55px;
  width: 55px;
  background: #128c7e;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
  font-size: 30px;
  transform: scaleX(-1);
}

#call-us .call-us-inner i {
  animation: phone 1.5s linear 0s infinite alternate;
}

.line {
  width: 70px;
  background: #fff;
  height: 1px;
}

.line-height-normal {
  line-height: normal;
}

input:focus-visible {
  outline: 0;
}

input[type="checkbox"] {
  height: 17px;
  width: 17px;
  border: 2px solid #d0d0d0;
  border-radius: 0 !important;
  position: relative;
  background-color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="checkbox"].form-check-input {
  margin: 0;
}

input[type="checkbox"].form-check-input:focus {
  border-color: rgba(0, 0, 0, 0.2509803922);
  box-shadow: none;
}

input[type="checkbox"].form-check-input:checked {
  background-color: #bf9f65;
  border-color: #bf9f65;
}

input[type="radio"] {
  cursor: pointer;
}

input[type="radio"]:checked {
  background-color: #bf9f65;
  border-color: #bf9f65;
}

input[type="radio"]:focus {
  border-color: #bf9f65;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(176, 130, 24, 0.2509803922);
}

input[type="range"]::-webkit-slider-thumb {
  background: #bf9f65;
}

input[type="range"]::-webkit-slider-thumb:focus {
  background: #bf9f65 !important;
}

.form [class*="col-"] {
  margin-bottom: 10px;
}

.form [class*="col-"]:last-child {
  margin-bottom: 0;
}

.form .form-control {
  /* border: 1px solid #bf9f65; */
  padding: 12px 1pc;
  font-weight: 500;
  color: #bf9f65;
  background-color: transparent;
  /* border-radius: 80px; */
}

select {
  padding-left: 30px;
}

.form .form-control:focus {
  box-shadow: none;
  transition: all 0.5s;
}

.form .form-control::placeholder {
  box-shadow: none;
  transition: all 0.5s;
  font-size: 15px;
  color: #bf9f65;
}

.form .form-control:focus::-moz-placeholder {
  opacity: 0;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  padding-left: 30px !important;
}

.form .form-control:focus::placeholder {
  opacity: 0;
  transition: all 0.5s;
}

.form .form-control::-moz-placeholder {
  opacity: 1;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  text-transform: capitalize;
}

.form .form-control::placeholder {
  opacity: 1;
  transition: all 0.5s;
  text-transform: capitalize;
}

.form label {
  margin: 0;
  color: #000000;
  line-height: 20px;
  width: 100%;
  font-size: 15px;
  font-weight: 600;
  /* padding-left: 30px; */
}

.form-control[type="file"] {
  padding-left: 30px;
}

.address-map {
  margin: 0 10px;
  padding: 8px 60px 8px 8px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 2px;
  background-color: white;
  position: absolute;
  top: -5px;
  left: -5px;
  text-align: left;
  z-index: 1;
}

.address-map p,
.address-map a {
  font-size: 15px;
}

.check {
  -webkit-appearance: auto !important;
  -moz-appearance: auto !important;
  appearance: auto !important;
}

/*# sourceMappingURL=main.css.map */

/* pagination for shop page */
#react-paginate {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

#react-paginate ul {
  gap: 20px;
}

#react-paginate li {
  display: inline-block;
  /* border: 1px solid rgb(224, 224, 224);
  color: #000; */
  cursor: pointer;
  margin-right: 3px;
  border-radius: 5px;
  margin-bottom: 5px;
  border-radius: 50%;
  color: #fff !important;
}

#react-paginate li a {
  width: 45px !important;
  height: 45px !important;
  border-radius: 50% !important;
  text-align: center !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #bf9f65 !important;
  background-color: transparent !important;
  border: 1px solid #bf9f65 !important;
}

#react-paginate li a svg {
  font-size: 20px;
}

#react-paginate li.active a {
  background: #bf9f65 !important;
  outline: none;
  color: #fff !important;
}

@media only screen and (min-width: 500px) and (max-width: 991px) {
  #react-paginate {
    margin-bottom: 0px !important;
  }

  #react-paginate ul {
    gap: 6px;
    margin-bottom: 0px;
  }

  #react-paginate li a {
    width: 30px !important;
    height: 30px !important;
    font-size: 14px !important;
  }
}
@media only screen and (max-width: 500px) {
  #react-paginate {
    margin-bottom: 0px !important;
  }

  #react-paginate ul {
    gap: 3px;
    margin-bottom: 0px;
  }

  #react-paginate li a {
    width: 25px !important;
    height: 25px !important;
    font-size: 10px !important;
  }
}
.formheading {
  background: #bf9f65;
  font-weight: 600;
  border-radius: 45px;
  font-size: 30px;
  color: white;
  text-align: center;
  padding: 20px;
}

.frormcontainer {
  background: #f5f1e8;
  border-radius: 20px;
}

.shop-search {
  padding: 0.3rem 0.2rem 0.3rem 0.7rem !important;
}

.opportunities_btn {
  background-color: rgb(96, 50, 0);
  bottom: 2rem;
  left: 50%;
  transform: translateX(-245%);
}

@media (min-width: 940px) and (max-width: 1130px) {
  .opportunities_btn {
    background-color: rgb(96, 50, 0);
    bottom: 2rem;
    left: 50%;
    transform: translateX(-155%);
  }
}

@media (min-width: 761px) and (max-width: 939px) {
  .opportunities_btn {
    background-color: rgb(96, 50, 0);
    bottom: 1rem;
    left: 50%;
    transform: translateX(-125%);
    padding: 1rem !important;
  }
}

/* @media (min-width: 640px) and (max-width: 760px) {
  .opportunities_btn{
    background-color: rgb(96, 50, 0);
    bottom: 1rem;
    left: 50%;
    transform: translateX(-125%);
  
    padding: .5rem !important;
    font-size: 12px ! important;
    font-weight: 500 !important;
  }
} */

@media (min-width: 588px) and (max-width: 760px) {
  .opportunities_btn {
    background-color: rgb(96, 50, 0);
    bottom: 1rem;
    left: 50%;
    transform: translateX(-125%);
    padding: 0.4rem !important;
    font-size: 15px !important;
    font-weight: 500 !important;
  }
}

@media (min-width: 480px) and (max-width: 587px) {
  .opportunities_btn {
    background-color: rgb(96, 50, 0);
    bottom: 0.1rem;
    left: 50%;

    transform: translate(-65%, -41%);
    padding: 0.4rem !important;
    font-size: 18px !important;
    font-weight: 500 !important;
  }
}

@media (min-width: 412px) and (max-width: 479px) {
  .opportunities_btn {
    background-color: rgb(96, 50, 0);
    bottom: 0.4rem;
    left: 50%;
    width: 99px;
    transform: translate(-61%, -41%);
    padding: 0.2rem !important;
    font-size: 18px !important;
    font-weight: 500 !important;
  }
}

@media (min-width: 356px) and (max-width: 411px) {
  .opportunities_btn {
    background-color: rgb(96, 50, 0);
    bottom: 0.2rem;
    left: 50%;
    width: 99px;
    transform: translate(-50%, -41%);
    padding: 0.1rem !important;
    font-size: 18px !important;
    font-weight: 500 !important;
  }
}

@media (min-width: 288px) and (max-width: 355px) {
  .opportunities_btn {
    background-color: rgb(96, 50, 0);
    bottom: -0.4rem;
    left: 50%;
    width: 85px;
    transform: translate(-50%, -41%);
    padding: 0.1rem !important;
    font-size: 15px !important;
    font-weight: 400 !important;
  }
}

@media (max-width: 286px) {
  .opportunities_btn {
    background-color: rgb(96, 50, 0);
    bottom: -0.4rem;
    left: 50%;
    width: 75px;
    transform: translate(-55%, -41%);
    padding: 0.1rem !important;
    font-size: 15px !important;
    font-weight: 400 !important;
  }
}
